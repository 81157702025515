import React, { Component } from 'react'
import { NavLink as RRNavLink } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Isvg from 'react-inlinesvg';

import LeftSideMenu from '../components/LeftMenu.js';
import drop_down from '../images/svg/drop_down.svg';

import {
    Container, Row, Col, NavItem, NavLink
} from 'reactstrap';

const menuJson = [
    {
        _id: 1,
        text: "Obavezno osiguranje putnika u javnom prevozu",
        link: "/insurancePassengers"
    },
    {
        _id: 2,
        text: "Osiguranje autonezgode",
        link: "/accidentInsurance"
    },
    {
        _id: 3,
        text: "Kolektivno kombinovano osiguranje zaposlenih radnika",
        link: "/combinedInsurance"
    },
    {
        _id: 4,
        text: "Kolektivno osiguranje zaposlenih radnika",
        link: "/collectiveInsurance"
    }
    ,
    {
        _id: 4,
        text: "Osiguranje učenika I studenata",
        link: "/studentsInsurance"
    },
    {
        _id: 5,
        text: "Osiguranje gostiju hotela, motela, kampova, banjskih liječilišta i slično",
        link: "/guestInsurance"
    },
    {
        _id: 6,
        text: "Osiguranje članova sportskih organizacija",
        link: "/sportsinsurance"
    }

]

export class accidets extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: true
        }
    }
    render() {
        return (
            <>
                <Col xl="3" lg="3" md="4" xs="12">
                    <div className="propertyBox">
                        <div className="contentBox">
                        <div className="wrappButton">
                            <Link to="/accidents"> <h2>NEZGODE</h2></Link>
                            <button className="d-md-none" onClick={() => this.setState({ open: !this.state.open })}> <Isvg src={drop_down} /></button>
                            </div>


                            {this.state.open ?
                            <LeftSideMenu items={menuJson} />
                            : null}
                        </div>
                    </div>
                </Col>

            </>
        )
    }
}

export default accidets
