import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import Home from './views/home.js';
import About from './views/about';
import AllLocations from './views/allLocations.js';
import DetailLocation from './views/detailLocation.js';
import Property from './views/property/property.js';
import Lom from './views/property/lom';
import Pozar from './views/property/pozar.js';
import Provale from './views/property/provale.js';
import Opasnost from './views/property/opasnost.js';
import StvariDomacinstvo from './views/property/stvariDomacinstvo.js';
import Contact from './views/contact.js';
import Gallery from './views/gallery.js';
import News from './views/news.js';
import NewsDetail from './views/newsDetail.js';
import Accidents from './views/accidents.js'
import Reports from './views/reports.js';
import Regulations from './views/regulations.js';
import Complaints from './views/complaints.js';
import Damage from './views/damage.js';
import ReportDamage from './views/damage/reportDamage.js';
import RequiredDoc from './views/damage/requiredDoc.js';
import Transport from './views/transport.js';
import Responsibility from './views/responsibility.js';
import Healthy from './views/healthy.js';
import Authoutity from './views/authoutity.js';
import Kasko from './views/kasko.js';
import InsurancePassengers from './views/accidents/insurancePassengers.js';
import AccidentInsurance from './views/accidents/accidentInsurance.js';
import CombinedInsurance from './views/accidents/combinedInsurance.js';
import CollectiveInsurance from './views/accidents/collectiveInsurance.js';
import StudentsInsurance from './views/accidents/studentsInsurance.js';
import GuestInsurance from './views/accidents/guestInsurance.js';
import SportsInsurance from './views/accidents/sportsinsurance.js';
import Service from './views/service';










import GoogleMap from './googleMapScript.js';

import './App.css';
import { Core } from './core.js';



class App extends React.Component {

  constructor(props) {
    super(props);
    this.callback = this.callback.bind(this);
    window.googleMapsCallback = this.callback;
    this.core = new Core();

    this.state = {
      services: [],
      cities: [],
      offices: [],
      gallery: [],
      texts: {}
    };
  }
  callback() {
    this.setState({ mapinit: true });
  }

  componentDidMount(){
    this.core.fetchServices((data) => {
      this.setState({
        services: data
      })
    })

    this.core.fetchOffices((data) => {
      this.setState({
        offices: data
      })
    })


    this.core.fetchCities((data) => {
      this.setState({
        cities: data
      })
    })
    this.core.fetchGallery((data) => {
      console.log(data);
      this.setState({
        gallery: data
      })
    })
    this.core.fetchTexts((data) => {
      console.log(data);
      this.setState({
        texts: data
      })
    })

  }

  render() {
    return (
      <div>
        <GoogleMap API_KEY="AIzaSyAAqbIo7N0_Rpwtay3-CWzo5gkfpgWZ4to" />

{/*        AIzaSyAAqbIo7N0_Rpwtay3-CWzo5gkfpgWZ4to*/}

        <Router>
          <Switch>
            <Route exact path="/" render={(...renderProps) => (<Home {...renderProps} core={this.core} {...this.state}></Home>)} />
            <Route exact path="/about" render={(...renderProps) => (<About  {...renderProps} core={this.core} {...this.state}  />)} />
            <Route exact path="/AllLocations" render={(...renderProps) => <AllLocations {...renderProps} core={this.core} {...this.state} />}/>
            <Route exact path="/detailLocation/:id/:name" render={(...renderProps) => (<DetailLocation {...renderProps} core={this.core} {...this.state}  ></DetailLocation>)} />
            <Route exact path="/news" render={(...renderProps) => <News {...renderProps} core={this.core} {...this.state} />}/>
            <Route exact path="/contact" render={(...renderProps) => <Contact {...renderProps} core={this.core} {...this.state} />}/>
            <Route exact path="/gallery" render={(...renderProps) => <Gallery {...renderProps} core={this.core} {...this.state} />}/>
            <Route exact path="/news/:title/:id" render={(...renderProps) => <NewsDetail {...renderProps} core={this.core} {...this.state} /> }/>
            <Route exact path="/osiguranje/:id/:alias" render={(...renderProps) => <Service {...renderProps} core={this.core} {...this.state} /> }/>



            <Route exact path="/property" render={(...renderProps) => <Property {...renderProps} core={this.core} {...this.state} /> }/>
            <Route exact path="/pozar" render={(...renderProps) => <Pozar {...renderProps} core={this.core} {...this.state} /> }/>
            <Route exact path="/opasnost" render={(...renderProps) => <Opasnost {...renderProps} core={this.core} {...this.state} /> }/>
            <Route exact path="/provale" render={(...renderProps) => <Provale {...renderProps} core={this.core} {...this.state} /> }/>
            <Route exact path="/lom" render={(...renderProps) => <Lom {...renderProps} core={this.core} {...this.state} /> }/>
            <Route exact path="/stvariDomacinstvo" render={(...renderProps) => <StvariDomacinstvo {...renderProps} core={this.core} {...this.state} /> }/>
            <Route exact path="/reports" render={(...renderProps) => <Reports {...renderProps} core={this.core} {...this.state} /> }/>
            <Route exact path="/regulations" render={(...renderProps) => <Regulations {...renderProps} core={this.core} {...this.state} /> }/>
            <Route exact path="/complaints" render={(...renderProps) => <Complaints {...renderProps} core={this.core} {...this.state} /> }/>
            <Route exact path="/damage" render={(...renderProps) => <Damage {...renderProps} core={this.core} {...this.state} /> }/>
            <Route exact path="/accidents" render={(...renderProps) => <Accidents {...renderProps} core={this.core} {...this.state} /> }/>
            <Route exact path="/reportDamage" render={(...renderProps) => <ReportDamage {...renderProps} core={this.core} {...this.state} /> }/>
            <Route exact path="/requiredDoc" render={(...renderProps) => <RequiredDoc {...renderProps} core={this.core} {...this.state} /> }/>
            <Route exact path="/transport" render={(...renderProps) => <Transport {...renderProps} core={this.core} {...this.state} /> }/>
            <Route exact path="/responsibility" render={(...renderProps) => <Responsibility {...renderProps} core={this.core} {...this.state} /> }/>
            <Route exact path="/healthy" render={(...renderProps) => <Healthy {...renderProps} core={this.core} {...this.state} /> }/>
            <Route exact path="/authoutity" render={(...renderProps) => <Authoutity {...renderProps} core={this.core} {...this.state} /> }/>
            <Route exact path="/kasko" render={(...renderProps) => <Kasko {...renderProps} core={this.core} {...this.state} /> }/>
            <Route exact path="/insurancePassengers" render={(...renderProps) => <InsurancePassengers {...renderProps} core={this.core} {...this.state} /> }/>
            <Route exact path="/accidentInsurance" render={(...renderProps) => <AccidentInsurance {...renderProps} core={this.core} {...this.state} /> }/>
            <Route exact path="/combinedInsurance" render={(...renderProps) => <CombinedInsurance {...renderProps} core={this.core} {...this.state} /> }/>
            <Route exact path="/collectiveInsurance" render={(...renderProps) => <CollectiveInsurance {...renderProps} core={this.core} {...this.state} /> }/>
            <Route exact path="/studentsInsurance" render={(...renderProps) => <StudentsInsurance {...renderProps} core={this.core} {...this.state} /> }/>
            <Route exact path="/guestInsurance" render={(...renderProps) => <GuestInsurance {...renderProps} core={this.core} {...this.state} /> }/>
            <Route exact path="/sportsInsurance" render={(...renderProps) => <SportsInsurance {...renderProps} core={this.core} {...this.state} /> }/>



         
          </Switch>
        </Router>
      </div>
    )
  }
}

export default App;
