import React, { Component } from 'react'
import DefaultLayout from '../containers/defaultLayout';
import { Link } from 'react-router-dom';
import Isvg from 'react-inlinesvg';
import downArrow from '../images/svg/down-arrow.svg';


import { Container, Row, Col } from 'reactstrap';

export class regulations extends Component {

    constructor(props){
        super(props);

        this.state  ={
            downloads: []
        }
    }

    componentDidMount(){
        this.props.core.fetchDownloads({ category: 'propisi' }, (data) => {

            this.setState({
                downloads: data,
            })
        })

    }
    componentWillMount(){
        window.scroll(0,0);
    }
    render() {
        return (

            <>
                <Container>
                    <div className="bredcrumb">
                        <h3>PROPISI</h3>

                        <p> <Link to="/">Početna</Link> / <span>Propisi</span></p>
                    </div>
                </Container>
                <div className="regulations">



                    <div className="wrapper">
                        <h6> PROPISI</h6>
                    </div>
                    <Container>
                        <Row>
                            <Col xl="12" lg="12" md="12" xs="12">
                                {
                                    this.state.downloads.map((item, idx) => {
                                        return (
                                            <div className="reportBox">
                                            <a target="_blank" href={item.file}><p>{item.name}</p></a> 
                                            <a target="_blank" href={item.file}><Isvg src={downArrow} /></a>
                                        </div>
        
                                        )
                                    })
                                }

                                {/*
                                <div className="reportBox">
                                    <p>Zakon o društvima za osiguranje (Sl. glasnik RS br. 17-05, izmjene u br. 01-06 i 64-06</p>
                                    <Link to="#"><Isvg src={downArrow} /></Link>
                                </div>
                                <div className="reportBox">
                                    <p>Zakon o izmjenama i dopunama zakona o društvima za osiguranje (Sl. glasnik RS br. 74-10</p>
                                    <Link to="#"><Isvg src={downArrow} /></Link>
                                </div>
                                <div className="reportBox">
                                    <p>Zakon o obaveznim osiguranjima u saobraćaju Sl.glasnik Republike Srpske br. 82-15</p>
                                    <Link to="#"><Isvg src={downArrow} /></Link>
                                </div>
                                <div className="reportBox">
                                    <p>Zakon o posredovanju u osiguranju (Sl. glasnik RS br. 17-05, izmjene u br. 64-04)</p>
                                    <Link to="#"><Isvg src={downArrow} /></Link>
                                </div>
                                <div className="reportBox">
                                    <p>Zakon o izmjenama  i dopunama zakona o posredovanju u osiguranju (Sl. glasnik RS br. 106-09</p>
                                    <Link to="#"><Isvg src={downArrow} /></Link>
                                </div>
                                <div className="reportBox">
                                    <p>Uslovi za osiguranje vlasnika vazduhoplova od odgovornosti za štetu pričinjenu trećim licima i putnicima.</p>
                                    <Link to="#"><Isvg src={downArrow} /></Link>
                                </div>
                                <div className="reportBox">
                                    <p>Uslovi za osiguranje vlasnika plovila na motorni pogon od odgovornosti za štete pričinjene trećim licima i putnicima.</p>
                                    <Link to="#"><Isvg src={downArrow} /></Link>
                                </div>
                                    */}
                            </Col>
                        </Row>
                    </Container>
                </div>
            </>
        )
    }
}

export default DefaultLayout(regulations)
