import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import DefaultLayout from '../containers/defaultLayout';

import { Container, Row, Col } from 'reactstrap';
export class transport extends Component {


    componentWillMount(){
        window.scroll(0,0);
    }
    render() {
        return (

            <>
                <Container>
                    <div className="bredcrumb">
                        <h3>TRANSPORT</h3>

                        <p> <Link to="/">Početna</Link> / Osiguranja / <span>Transport</span></p>
                    </div>
                </Container>

                <div className="transport">

                    <div className="wrapper">
                        <h6>OSIGURANJA</h6>
                    </div>
                    <Container>
                        
                        <h2>OSIGURANJE ROBE U TRANSPORTU</h2>

                        <p>Roba koje se transportuje izložena je brojnim rizicima i to bez obzira na vrstu prevoznog sredstva. Kako ne bi bili prinuđeni da
                        sami snosite troškove u slučaju da se ostvari neki od rizika osigurajte robu koju prevozite. Na taj način dio ili ukupan iznos
                        troškova saniranja nastalih šteta snosi Vaš osiguravač.Transporti rizici kojima je roba izložena obično se razvrstavaju na osnovne,
                        dopunske i specijalne.</p>



                        <p><span>Osnovni rizici:</span> </p>

                        <li>  - saobraćajna nezgoda prevoznog sredstva (sudar, udar, potonuće, iskliznuće, pad u provaliju ili    vodu, kao i štete kod slijetanja,
                        polijetanja ili pada vazduhoplova);</li>
                        <li>    - elementarna nepogoda (oluja, udar groma , grad, sniježna lavina, odron zemljišta, prolom    oblaka, poplava, rušenje mostova i
                        tunela);</li>
                        <li>   - požar i eksplozija (kao djelovanje vanjskog uzroka, a ne kao svojstva osigurane stvari);</li>
                        <li>    - krađa, djelimična krađa i neisporuka cijelih koleta, kao i krađa prevoznog sredstva sa    pošiljkom…</li>





                        <p><span>Dopunski rizici:</span> </p>

                        <li>- lom i mehaničko ošteđenje osiguranih stvari;</li>
                        <li>- rasipanje ili curenje vode usljed ošteđenja spoljne transportne ambalaže,</li>
                        <li> - pokisnuće u zatvorenim prevoznim sredstvima;</li>
                        <li>- dodir sa drugim robama na istom prevoznom sredstvu…</li>




                        <p> <span> Specijalni rizici:</span> </p>

                        <li> - naduvavanje – bombaža limenki,</li>
                        <li> - rđa, oksidacija,</li>
                        <li> - manjak,</li>
                        <li> - kvar lakokvarljivih proizvoda (svježe meso, voće, povrće…),</li>
                        <li> - rizik uginuća,</li>
                        <li> - smrzavanje,</li>
                        <li> - zagrijavanje i znojenje…</li>

                        <p> Odabir rizika koji želite osigurati zavisi od Vas.</p>



                        <p><span>Za više detalja o navedenim vrstama osiguranja ali i mogućnostima nekih drugih vrsta osiguranja, kao što su:</span></p>

                        <li> - osiguranje objekata u izgradnji i montaži, </li>
                        <li> - osiguranje usled prekida rada (šomažno osiguranje), gubitak dohotka, nadoknada ličnih    zarada i sl., </li>
                        <li> - osiguranje odgovornosti iz djelatnosti, </li>
                        <li> - osiguranje usjeva i plodova, </li>
                        <li> - osiguranje životinja…</li>


                    </Container>
                </div>

            </>
        )
    }
}

export default DefaultLayout(transport)
