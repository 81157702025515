import React, { Component } from 'react'



import bgc from '../../images/why/bgc.png';
import {
    Container, Row, Col, Button
} from 'reactstrap';


import AnimtedButton from '../../components/animatedButton.js';
export class why extends Component {
    render() {

        const buttonText = "DETALJNIJE";
        return (
            <>
            
      

                <div className="whyAs">
                <div className="wrapper">
                    <h6>ZAŠTO MI</h6>
                </div>

                    <div className="eee">
                        <div className="imageWrapper">
                            <img src={bgc} className="img-fluid" />
                            </div>
                    </div>
                    <Container>
                        <Row>
                            <Col xl="7" lg="6" md="7">
                                <div className="whyAsContent">
                                    <h4>ZAŠTO NEŠKOVIĆ OSIGURANJE</h4>
                                    <h3>{this.props.texts['zasto-neskovic-osiguranje'] && this.props.texts['zasto-neskovic-osiguranje'].title}</h3>
                                    <div dangerouslySetInnerHTML={{__html: this.props.texts['zasto-neskovic-osiguranje'] && this.props.texts['zasto-neskovic-osiguranje'].content}}>
                                    </div>
                                    <div className="button">
                                        <AnimtedButton buttonText={buttonText} />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>

                </div>
            </>
        )
    }
}

export default why
