import React, { Component } from 'react'
import DefaultLayout from '../../containers/defaultLayout';
import { Link } from 'react-router-dom';

import AccidentsMenu from '../../containers/accidentsMenu.js';

import { Container, Row, Col } from 'reactstrap';


export class insurancePassengers extends Component {


    componentWillMount(){
        window.scroll(0,0);
    }
    render() {
        return (
            <>
                <Container>
                    <div className="bredcrumb">
                        <h3>NEZGODE</h3>

                        <p> <Link to="/">Početna</Link> / Osiguranja / <span>Nezgode</span></p>
                    </div>
                </Container>
                <div className="accidents">
                    <div className="wrapper">
                        <h6>OSIGURANJA</h6>
                    </div>




                    <Container>
                        <Row>
                            <AccidentsMenu />

                            <Col xl="9" lg="9" md="8" xs="12">
                                <div className="content">
                                    <h2>OBAVEZNO OSIGURANJE PUTNIKA U JAVNOM PREVOZU</h2>

                                 <p>   Pravna i fizička lica koja se bave prevozom putnika u javnom saobraćaju imaju zakonsku obavezu 
                                    da osiguraju putnike od posljedica nesrećnog slučaja. Dakle, ovaj vid osiguranja ugovara 
                                    prevoznik, koji je obavezan i platiti premiju osiguranja. </p>

                                   <p><span> Ukoliko se ostvari nesrećan slučaj korisnik osiguranja – osiguranik (putnik) ima pravo na:</span></p>
                                    
                                  <li>- osiguranu sumu za slučaj smrti, ako je uslijed nezgode nastupila smrt osiguranika ili</li>
                                  <li> - procenat od osigurane sume koji odgovara procentu invaliditeta, ako je uslijed nezgode kod 
                                    osiguranika nastupio invaliditet.</li>
                                    
                                 <p>Dopunski Uslovi za osiguranje putnika u JP od posledica nesrećnog slucaja</p>   
                                </div>
                            </Col>
                        </Row>
                    </Container>



                </div>

            </>
        )
    }
}

export default DefaultLayout(insurancePassengers)
