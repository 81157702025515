import React, { Component } from 'react'
import Isvg from 'react-inlinesvg';
import Navigation from '../containers/navbar.js';
import Footer from '../containers/footer.js';
import { Link } from 'react-router-dom';
import AnimtedButton from '../components/animatedButton.js';
import DefaultLayout from '../containers/defaultLayout';

import drop_down from '../images/svg/drop_down.svg';

import {
    Container, Row, Col, FormGroup, Input, Button
} from 'reactstrap';
import ContactForm from '../containers/forms/contactForm';
import { throws } from 'assert';
export class contact extends Component {
    constructor(props) {
        super(props);

        this.state = {
            items: []
        }
    }

    componentDidMount() {
        this.props.core.fetchContactPersons((data) => {
            this.setState({
                items: data
            })
        })
    }

    componentWillMount() {
        window.scroll(0, 0);
    }
    render() {
        const buttonText = "POŠALJI";

        return (

            <div>


                <Container>
                    <div className="bredcrumb">
                        <h3>KONTAKT</h3>

                        <p> <Link to="/">Početna</Link> / <span>Kontakt</span></p>
                    </div>
                </Container>
                <Container>
                    <div className="contact">
                        <div className="wrapper">
                            <h6>KONTAKT</h6>
                        </div>
                        <h2>KONTAKTIRAJTE NAS</h2>
                        <p>Budite slobodni da nas kontaktirate. Odgovorićemo Vam u što kraćem roku.</p>



                        <Row>
                            <Col xl="6" lg="6" md="6" xs="12">
                                <div className="form">
                                    <ContactForm onSubmit={(dat) => {
                                        this.props.core.contact(dat, () => {
                                            this.setState({
                                                _done: true
                                            })
                                        });
                                    }}></ContactForm>

{this.state._done ?
                                    <div className="alert alert-success messages" role="alert">
                                        <p>Vaša poruka je uspješno poslata, očekujte odgovor ubrzo.</p>
                                    </div>

                                    :
                                    null
                                }

                                </div>
                            </Col>

                            <Col xl="6" lg="6" md="6" xs="12">
                                {
                                    this.state.items.map((item, idx) => {
                                        return (
                                            <div key={idx}
                                                onClick={() => {
                                                    this.setState({ selected: item._id });
                                                }}
                                                className={this.state.selected == item._id ? "contactInfo open" : "contactInfo"}>
                                                <h3>{item.name}</h3>
                                                <Isvg src={drop_down} />
                                                {this.state.selected == item._id ?
                                                    <>
                                                        <h2>{item.person}</h2>
                                                        <p>Tel:{item.tel}</p>
                                                        <p>E-mail: {item.email}</p>{ item.email1 ?
                                                        <p>E-mail: {item.email1}net</p>
                                                        : null
                                                        }
                                                    </>
                                                    : null}

                                            </div>

                                        )
                                    })
                                }

                            </Col>

                        </Row>



                    </div>
                </Container>

            </div>
        )
    }
}

export default DefaultLayout(contact)
