import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import DefaultLayout from '../containers/defaultLayout';
import Isvg from 'react-inlinesvg';
import downArrow from '../images/svg/down-arrow.svg';


import { Container, Row, Col } from 'reactstrap';


export class complaints extends Component {
    constructor(props) {
        super(props);

        this.state = {
            downloads: []
        }
    }

    componentDidMount() {
        this.props.core.fetchDownloads({ category: 'prituzbe' }, (data) => {

            this.setState({
                downloads: data,
            })
        })

    }
    componentWillMount(){
        window.scroll(0,0);
    }
    render() {
        return (
            <>
                <Container>
                    <div className="bredcrumb">
                        <h3>PRITUŽBE</h3>

                        <p> <Link to="/">Početna</Link> / <span>Pritužbe</span></p>
                    </div>
                </Container>
                <div className="complaints">
                    <div className="wrapper">
                        <h6>PRITUŽBE</h6>
                    </div>
                    <Container>
                        <Row>
                            <Col xl="12" lg="12" md="12" xs="12">
                                {
                                    this.state.downloads.map((item, idx) => {
                                        return (
                                            <div className="reportBox">
                                                <a target="_blank" href={item.file}><p>{item.name}</p></a>
                                                <a target="_blank" href={item.file}><Isvg src={downArrow} /></a>
                                            </div>

                                        )
                                    })
                                }
                                {/*

                                <div className="reportBox">
                                    <p>Obavještenje o pritužbi</p>
                                    <Link to="#"><Isvg src={downArrow} /></Link>
                                </div>
                                <div className="reportBox">
                                    <p>Zapisnik o pritužbi</p>
                                    <Link to="#"><Isvg src={downArrow} /></Link>
                                </div>
*/}
                            </Col>
                        </Row>
                    </Container>

                </div>
            </>
        )
    }
}

export default DefaultLayout(complaints)
