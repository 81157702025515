import React, { Component } from 'react'
import Isvg from 'react-inlinesvg';
import { Link } from 'react-router-dom';
import Map from '../../containers/map.js';
import poslovnica from '../../images/poslovnica.png';
import drop_down from '../../images/svg/drop_down.svg';
import zoom from '../../images/svg/zoom.svg';
import AnimtedButton from '../../components/animatedButton.js';
import {
    Container, Row, Col, Button, FormGroup, Label, Input
} from 'reactstrap';

export class ourOffices extends Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = {
            dropdownOpen: false,
            width: 0,
            height: 0,
            search: false,
            city: 0,
            office: 0
        }

        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }




    toggle() {
        this.setState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen
        }));
    }
    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }
    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }


    render() {


        const buttonText = "DETALJNIJE";

        return (
            <div className="ourOffices">


                <Container>
                    <h1>NAŠE POSLOVNICE</h1>
                    <p>Posjetite prodajno mjesto Nešković osiguranja u vašem gradu i izaberite pouzdanog partnera za bezbjedan i siguran život!</p>
                    <Button className="button d-md-none" onClick={() => this.setState({ search: !this.state.search })}>Traži poslovnice <Isvg src={zoom} /> </Button>
                </Container>

                <div className="mapWraper">

                    <div className="wrapper">
                        <h6>POSLOVNICE</h6>
                    </div>
                    <Map {...this.props} coords={this.props.offices && this.state.office !== null && this.props.offices[this.state.office] && this.props.offices[this.state.office].location} />
                    <Container>
                        <Row>
                            <Col xl="4" lg="4" md="4" xs="12" className="offset-1 offset-xl-8 offset-lg-8 offset-md-8">
                                {this.state.width >= 767 || this.state.search == true ?
                                    <div className="chosenBox">
                                        <img src={poslovnica} className="img-fluid" />
                                        <div className="drop">
                                            <FormGroup>
                                                <Input type="select" name="select" id="exampleSelect" value={this.state.city} onChange={(e) => {
                                                    this.setState({ city: e.target.value })
                                                    for(let i=0;i<this.props.offices.length;i++){
                                                        if (this.props.cities[e.target.value]._id == this.props.offices[i].city){
                                                            this.setState({
                                                                office: i
                                                            })
                                                            break;
                                                        }
                                                    }
                                                 }}>
                                                    <option selected disabled>Grad</option>
                                                    {
                                                        this.props.cities.map((item, idx) => {
                                                            return (
                                                                <option value={idx} key={idx}>{item.name}</option>
                                                            )
                                                        })
                                                    }
                                                </Input>
                                                <Isvg src={drop_down} />
                                            </FormGroup>
                                        </div>

                                        <div className="drop">
                                            <FormGroup>
                                                <Input type="select" name="select" id="exampleSelect" value={this.state.office} onChange={(e) => this.setState({ office: e.target.value })}>
                                                    <option selected disabled>Poslovnica</option>
                                                    {
                                                        this.state.city !== null && this.props.cities && this.props.cities[this.state.city] ?
                                                            this.props.offices.map((item, idx) => {
                                                                if (this.props.cities[this.state.city]._id == item.city)
                                                                    return (
                                                                        <option key={idx} value={idx}>{item.name}</option>
                                                                    )
                                                            })
                                                            :

                                                            null
                                                    }
                                                </Input>
                                                <Isvg src={drop_down} />
                                            </FormGroup>
                                        </div>

                                        <p>{this.props.offices && this.state.office !== null && this.props.offices[this.state.office] && this.props.offices[this.state.office].tel}</p>
                                        <p>info@neskovicosiguranje.com</p>
                                        <Link to={`/detailLocation/${this.state.city}/${this.props.cities && this.props.cities[this.state.city] && this.props.cities[this.state.city].name && this.props.cities[this.state.city].name.toLowerCase().replace(/ /g, '-')}`}>
                                            <AnimtedButton buttonText={buttonText} />
                                        </Link>
                                    </div>
                                    : null}

                            </Col>
                        </Row>
                    </Container>
                </div>



            </div>
        )
    }
}

export default ourOffices
