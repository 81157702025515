import React, { Component } from 'react'
import Isvg from 'react-inlinesvg';
import { Link } from 'react-router-dom';
import Slider from "react-slick";

import slide1 from '../../images/sliderGallery/slide1.png';
import slide2 from '../../images/sliderGallery/slide2.png';


import AnimtedButton from '../../components/animatedButton.js';




import {
    Container, Row, Col, Button,     Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators
} from 'reactstrap';



const pictures = [
    {
        src: slide1,
        altText: 'Picture1',
        caption: 'Picture1'
    },
    {
        src: slide2,
        altText: 'Picture2',
        caption: 'Picture2'
    },
    {
        src: slide1,
        altText: 'Picture3',
        caption: 'Picture3'
    },
  
];



export class gallery extends Component {
    constructor(props){
        super(props);

        this.state = {
            activeIndex: 0,
        };
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.goToIndex = this.goToIndex.bind(this);
        this.onExiting = this.onExiting.bind(this);
        this.onExited = this.onExited.bind(this);
    }
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    
    onExiting() {
        this.animating = true;
    }

    onExited() {
        this.animating = false;
    }

    next() {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === this.props.gallery.length - 1 ? 0 : this.state.activeIndex + 1;
        this.setState({ activeIndex: nextIndex });
    }

    previous() {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === 0 ? this.props.gallery.length - 1 : this.state.activeIndex - 1;
        this.setState({ activeIndex: nextIndex });
    }

    goToIndex(newIndex) {
        if (this.animating) return;
        this.setState({ activeIndex: newIndex });
    }

    render() {
        const activeIndex = this.state.activeIndex;
        const slides = this.props.gallery.map((item) => {
            return (
                <CarouselItem className="bg"
                    onExiting={this.onExiting}
                    onExited={this.onExited}
                    key={item}
                >
                    <img src={item} className="img-fluid" />
                    <div className="carousel-caption">
                        <div className="container">
                            <div className="row d-flex justify-content-start">
                                <div className="col-xl-8 col-lg-12 col-md-12 col-sm-12 col-12">
                                </div>
                            </div>
                        </div>
                    </div>
                </CarouselItem>
            )
        });




        const buttonText = "KOMPLETNA GALERIJA";
        const settings = {
            // centerMode: true,
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1
                    }
                }
            ]
        };

        return (
            <div className="gallery">
                    <div className="wrapper">
                    <h6>GALERIJA</h6>
                </div>
                <Container>
                    <h1>GALERIJA</h1>
                    <p>Galerija naših poslovnica širom Republike Srpske.</p>

                    <Slider {...settings}>
                        <div className="pictures" onClick={() => this.setState({ opened: true, activeIndex: 0 })}>
                            <img src={slide1} className="img-fluid" />
                        </div>
                        <div className="pictures" onClick={() => this.setState({ opened: true, activeIndex: 1 })}>
                            <img src={slide2} className="img-fluid" />
                        </div>
                        <div className="pictures" onClick={() => this.setState({ opened: true, activeIndex: 0 })}>
                            <img src={slide1} className="img-fluid" />
                        </div>
                        <div className="pictures" onClick={() => this.setState({ opened: true, activeIndex: 1 })}>
                            <img src={slide2} className="img-fluid" />
                        </div>
                    </Slider>
                    {this.state.opened ?
                            <div className="lightbox">
                                <Container>
                                    <Row>
                                        <i className="fa fa-times" aria-hidden="true" onClick={() => this.setState({ opened: false })}></i>

                                        <Carousel className="bg"
                                            activeIndex={activeIndex}
                                            next={this.next}
                                            previous={this.previous}
                                        >
                                            <CarouselIndicators items={this.props.gallery} activeIndex={activeIndex} onClickHandler={this.goToIndex} />
                                            {slides}
                                            <i class="fa fa-angle-left" onClick={this.previous}></i>
                                            <i class="fa fa-angle-right" onClick={this.next}></i>
                                        </Carousel>
                                    </Row>
                                </Container>
                            </div> : null
                        }

                    <Link to="/gallery"><AnimtedButton buttonText={buttonText} /></Link>

                </Container>
            </div>
        )
    }
}

export default gallery
