import React, { Component } from 'react'
import DefaultLayout from '../../containers/defaultLayout';
import { Link } from 'react-router-dom';

import AccidentsMenu from '../../containers/accidentsMenu.js';

import { Container, Row, Col } from 'reactstrap';

export class studentsInsurance extends Component {

    componentWillMount() {
        window.scroll(0, 0);
    }
    render() {
        return (
            <>
                <Container>
                    <div className="bredcrumb">
                        <h3>NEZGODE</h3>

                        <p> <Link to="/">Početna</Link> / Osiguranja / <span> Osiguranje učenika i studenata</span></p>
                    </div>
                </Container>
                <div className="accidents">
                    <div className="wrapper">
                        <h6>OSIGURANJA</h6>
                    </div>




                    <Container>
                        <Row>
                            <AccidentsMenu />

                            <Col xl="9" lg="9" md="8" xs="12">
                                <div className="content">
                                    <h2>OSIGURANJE UČENIKA I STUDENATA</h2>

                                    <p> Osiguranje učenika i studenata pokriva nesrećne slučajeve koji se dogode za vrijeme nastave i
                                    izvan školskih aktivnosti, odnosno obezbjeđena je maksimalna zaštita tokom 24 časa dnevno.
                                    Ugovarač ove vrste osiguranja su škole, fakulteti i ostale prosvetne ustanove.</p>
                                </div>


                            </Col>
                        </Row>
                    </Container>



                </div>

            </>
        )
    }
}

export default DefaultLayout(studentsInsurance)
