





import React, { Component } from 'react'
import { Link } from 'react-router-dom';


import Navigation from '../../containers/navbar.js';
import Footer from '../../containers/footer.js';

import PropertyMenu from '../../containers/propertyMenu.js';
import DefaultLayout from '../../containers/defaultLayout.js';
import {
    Container, Row, Col
} from 'reactstrap';

export class property extends Component {
    constructor(props) {
        super(props);

        this.state = {
            menu: {}
        }
    }

    componentWillMount(){
        window.scroll(0,0);
    }
    render() {

        return (
            <div>
                <Container>
                    <div className="bredcrumb">
                        <h3>IMOVINA</h3>
                        <p> <Link to="/">Početna</Link> / Osiguranja / Imovina  / <span> Osiguranje od provalne krađe i razbojništva</span></p>
                    </div>
                </Container>


                <div className="property">
                    <div className="wrapper">
                        <h6>OSIGURANJA</h6>
                    </div>
                    <Container>
                        <Row>
                            <PropertyMenu />
                            <Col xl="9" lg="9" md="8" xs="12">
                                <div className="domacinstvo">
                                    <h2>OSIGURANJE OD PROVALNE KRAĐE I
                                    RAZBOJNIŠTVA</h2>

                                    <p>Osiguranjem od provalne krađe i razbojništva pruža se osiguravajuća zaštita od rizika
                                    izvršenja ili pokušaja provalne krađe ili razbojništva kao i obijesti (vandalizma) u toku
                                    izvršenja provalne krađe.</p>

                                    <p><span> Predmet osiguranja mogu biti najraznovrsnije pokretne stvari:</span> </p>

                                    <li>- zalihe (robe, materijala, sirovina, poljoprivrednih proizvoda, gotovih proizvoda i nedovršenih
                                    proizvoda),</li>
                                    <li> - mašine, uređaji, instalacije, </li>
                                    <li> - transportna sredstva i sredstva veze, </li>
                                    <li> - inventar, </li>
                                    <li> - predmeti domaćinstva, </li>
                                    <li> - hartije od vrijednosti, </li>
                                    <li> - arhivska građa, </li>
                                    <li> - umjetnički predmeti, </li>
                                    <li>- stvari od plemenitih metala…</li>


                                    <p>Ukoliko dođe do uništenja ili krađe osiguranih stvari nadoknađuje se vrijednost
                                    osigurane stvari do sume osiguranja, a ukoliko je osigurana stvar samo oštećena
                                    adoknađuju se troškovi popravke (rad i materijal).</p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>



            </div>
        )
    }
}

export default DefaultLayout(property)



















