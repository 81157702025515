import React, { Component } from 'react'
import { Link } from 'react-router-dom';

import DefaultLayout from '../../containers/defaultLayout.js';

import Navigation from '../../containers/navbar.js';
import Footer from '../../containers/footer.js';

import PropertyMenu from '../../containers/propertyMenu.js'


import {
    Container, Row, Col
} from 'reactstrap';



export class property extends Component {
    constructor(props) {
        super(props);

        this.state = {
            menu: {}
        }
    }


    componentWillMount(){
        window.scroll(0,0);
    }
    render() {

    
        return (
            <div>

                <Container>
                    <div className="bredcrumb">
                        <h3>IMOVINA</h3>
                        <p> <Link to="/">Početna</Link> / Osiguranja / Imovina  / <span> Osiguranje stakla od loma</span></p>
                    </div>
                </Container>

                <div className="property">
                    <div className="wrapper">
                        <h6>OSIGURANJA</h6>
                    </div>
                    <Container>
                        <Row>
                            <PropertyMenu />
                            <Col xl="9" lg="9" md="8" xs="12">
                                <div className="domacinstvo">
                                    <h2>OSIGURANJE STAKLA OD LOMA</h2>


                                    <p> <span>Predmet ove vrste imovinskog osiguranja mogu biti: </span></p>

                                    <li>- sve vrste stakla, svjetleći natpisi i reklame, </li>
                                    <li>- slike, natpisi i ukrasi izrađeni na osiguranim staklima,</li>
                                    <li>  - mermerne ploče i ploče od vještačkog kamena na stolovima, pultovima, regalima i sl.,</li>
                                    <li>  - porcelanski umivaonici, klozetske šolje,</li>
                                    <li>  - kulturni, istorijski i nadgrobni spomenici od kamena, betona i metala,</li>
                                    <li> - stakla na vratima i prozorima i izložena stakla - uz uslov da se na jednom objektu osiguraju sva
                                    ova stakla,</li>
                                    <li> - neonske i ostale svjetleće cijevi sa pripadajućim uređajima i bez njih.</li>


                                    <p>   <span> Ovim osiguranjem se pruža osiguravajuća zaštita od uništenja ili oštećenja osiguranih stvari
                                    zbog ostvarenja bilo koje opasnosti (rizka) kojima su izložene osigurane stvari, osim za štetu
                                    prouzrokovanu:
                                    </span> </p>

                                    <li> - prilkom premještanja ili namještanja osiguranih stvari u prostorijama koje nisu označene u
                                    polisi osiguranja;</li>

                                    <li>- ogrebotinom, izjedanjem i sličnim ošteđenjima na površini osiguranih stvari;</li>
                                    <li>- klizanjem i slijeganjem tla;</li>

                                    <li>- kod nadgrobnih spomenika – prilikom otvaranja i zatvaranja grobnice.</li>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>



            </div>
        )
    }
}

export default DefaultLayout(property)















