import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import Isvg from 'react-inlinesvg';
import DefaultLayout from '../containers/defaultLayout';
import Navigation from '../containers/navbar.js';
import News from '../containers/news.js';
import Footer from '../containers/footer.js';
import NeskovicOsiguranje from '../containers/about/neskovicOsiguranje.js';
import NeskovicGroup from '../containers/about/NeskovicGroup.js';
import OsnovniPodaci from '../containers/about/osnovniPodaci.js';
import Djelatnost from '../containers/about/djelatnost.js';
import Istorijat from '../containers/about/istorijat.js';
import Struktura from '../containers/about/struktura.js';
import drop_down from '../images/svg/drop_down.svg';
import {
    Container, Row, Col, Button, NavItem, NavLink
} from 'reactstrap';



import news1 from '../images/news/news1.png';
import news2 from '../images/news/news2.png';
import news3 from '../images/news/news3.png';

const jsonNews = [
    {
        _id: 1,
        image: news1,
        title: "LOREM IPSUM DOLOR SIT AMET, CONSETETUR DIPSCING",
        description: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam.",
        date: "10",
        month: "jul",
        link: "/newsDetail"
    },
    {
        _id: 2,
        image: news2,
        title: "LOREM IPSUM DOLOR SIT AMET, CONSETETUR DIPSCING",
        description: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam.",
        date: "10",
        month: "jul",
        link: "/newsDetail"
    },
    {
        _id: 3,
        image: news3,
        title: "LOREM IPSUM DOLOR SIT AMET, CONSETETUR DIPSCING",
        description: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam.",
        date: "10",
        month: "jul",
        link: "/newsDetail"
    },
]


export class about extends Component {
    constructor(props) {
        super(props);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
      
        this.state = {
            width: 0,
            height: 0,

            menu: 'item1',
            items: [],
            selected: 0,
            news: [],
            open: true
        }
    }


    componentDidMount() {

        this.props.core.fetchAboutUs((data) => {
            this.setState({
                items: data
            })
        });

        this.props.core.fetchLastNews((data) => {
            this.setState({ news: data });
        })
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);

    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }
    componentWillMount(){
        window.scroll(0,0);
    }

    render() {
        
        return (
            <div>



                <Container>
                    <div className="bredcrumb">
                        <h3>O NAMA</h3>

                        <p> <Link to="/">Početna</Link> / O NAMA / <span>  {this.state.items && this.state.items[this.state.selected] && this.state.items[this.state.selected].title}

                        </span></p>
                    </div>
                </Container>

                <div className="about">
                    <div className="wrapper">
                        <h6>O NAMA</h6>
                    </div>



                    <Container>
                        <Row>
                            <Col xl="3" lg="3" md="4" xs="12">
                                <div className="aboutBox">
                                    <div className="contentBox">
                                        <div className="d-flex justify-content-between">
                                            <h2>O NAMA</h2>
                                            <button onClick={() => this.setState({ open: !this.state.open })} className="d-md-none "><Isvg src={drop_down} /></button>
                                        </div>
                                       
                                       

                                            <div className= {this.state.width < 767 ?  this.state.open ? "zatvoren" : "otvoren"  : ""}>
                                            
                                           
                                                {
                                                    this.state.items.map((item, idx) => {
                                                        return (
                                                            <li key={idx} className={this.state.selected == idx ? "activ" : ""}

                                                                onClick={() => {
                                                                    this.setState({ selected: idx });
                                                                }}>{item.title && item.title.toLowerCase()}
                                                            </li>

                                                        )
                                                    })
                                                }
                                            </div>
                                            
                                        
                                    </div>
                                </div>
                            </Col>

                            <Col xl="9" lg="9" md="8" xs="12">
                                <div className="content">
                                    <h4>{this.state.items && this.state.items[this.state.selected] && this.state.items[this.state.selected].title}</h4>
                                    <div dangerouslySetInnerHTML={{ __html: this.state.items && this.state.items[this.state.selected] && this.state.items[this.state.selected].content }}>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>

                </div>

                <News items={this.state.news} />

            </div>
        )
    }
}

export default DefaultLayout(about) 
