import React, { Component } from 'react'
import { NavLink as RRNavLink } from 'react-router-dom';
import {Link} from 'react-router-dom';

import {
    Container, Row, Col, NavItem, NavLink
} from 'reactstrap';



export class accidentsmenu extends Component {
    render() {
        return (
            <>
            

            
                            {this.props.items.map((items, idx) => {
                                return (
                                    <div key={items._id}>
                                        <NavItem>
                                            <NavLink to={items.link} exact tag={RRNavLink} activeClassName="activ">{items.text}</NavLink>
                                        </NavItem>
                                    </div>


                                )
                            })}
                     
              
            </>
        )
    }
}

export default accidentsmenu
