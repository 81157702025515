import React, { Component } from 'react'

export class struktura extends Component {
    render() {
        return (
            <div className="content">
                <h4>ORGANIZACIONA STRUKTURA</h4>
            </div>
        )
    }
}

export default struktura
