import React, { Component } from 'react'

export class osnovniPodaci extends Component {
    render() {
        return (
            <div className="content">
            <h4>NEŠKOVIĆ GROUP</h4>

            <ul>
                <li> <span>Naziv:</span> Nešković osiguranje AD </li>
                <li><span>Adresa:</span> Sremska 3, 76300 Bijeljina, Republika Srpska, Bosna i Hercegovina </li>

                <li><span>JIB:</span> 4400330410003</li>
                <li><span>PIB:</span> 400330410003 </li>



                <li><span>MB:</span> 1881019 </li>



                <li><span>Reg. uložak:</span> 1-3908 Osnovni sud Bijeljina </li>
                <li><span>Žiro račun:</span> 555-001-00003540-19 Nova Banka AD, Bijeljina </li>
            </ul>
                
            </div>
        )
    }
}

export default osnovniPodaci
