import React, { Component } from 'react'
import DefaultLayout from '../containers/defaultLayout';
import { Link } from 'react-router-dom';

import { Container } from 'reactstrap';

export class kasko extends Component {


    componentWillMount(){
        window.scroll(0,0);
    }
    render() {
        return (
            <>
                <Container>
                    <div className="bredcrumb">
                        <h3>KASKO</h3>

                        <p> <Link to="/">Početna</Link> / Osiguranja / <span>Kasko</span></p>
                    </div>
                </Container>
                <div className="healthy kasko">
                    <div className="wrapper">
                        <h6>OSIGURANJA</h6>
                    </div>
                    <Container>
                        <h2>ZDRAVSTVENO OSIGURANJE</h2>

                      <p>Kupovinom polise AO zaštitili ste tuđu imovinu ali kako da zaštitite svoju?</p>

                      <p>Učinite to tako što ćete kupiti polisu kasko osiguranja u svim poslovnim jedinicama NEŠKOVIĆ OSIGURANJA.
                        Kupovinom ove polise ostvarujete pravo na naknadu materijalne štete u saobraćajnoj nezgodi čak i kada je nezgoda
                        prouzrokovana Vašom krivicom.</p>

                      <p><span>  Uz kasko osiguranje ostvarićete pravo na naknadu štete i u slučaju kada je šteta prouzrokovana:</span></p>

                        <li>-padom ili udarom nekog predmeta,</li>
                        <li>-elementarnom nepogodom (poplava, bujica, udar groma, oluja, grad, odron zemljišta, snježna lavina…),</li>
                        <li> -požarom,</li>
                        <li> -eksplozijom…</li>

                        <p>Posebna pogodnost kasko osiguranja je u činjenici da Vam pruža i mogućnost da svoja motorna vozila zaštitite od rizika krađe.</p>

                        <p>NEŠKOVIĆ OSIGURANJE nudi više varijanti kasko osiguranja vozila a sve u skladu sa Vašim željama i mogućnostima.
                        Ukoliko se odlučite za kasko osiguranje sa franšizom, tj. učešćem u šteti, pri samoj kupovini polise platićete manju premiju, ali
                        isto tako morate znati da ukoliko se šteta dogodi dio troškova snosite i Vi.
                        Ugovaranjem potpunog kasko osiguranja cjelokupan iznos štete pada na osiguravajuću kuću čiju ste polisu kupili.
                        NEŠKOVIĆ OSIGURANJE Vam nudi i mogućnost izbora načina plaćanja premije osiguranja – u cjelini ili na rate.</p>
                    </Container>
                </div>
            </>
        )
    }
}

export default DefaultLayout(kasko)
