import React, { Component } from 'react'
import DefaultLayout from '../containers/defaultLayout';
import {Link} from 'react-router-dom';
import News from '../containers/news.js';
import detail from '../images/news/detail.png';

import {
    Container, Row, Col
} from 'reactstrap';

import news1 from '../images/news/news1.png';
import news2 from '../images/news/news2.png';
import news3 from '../images/news/news3.png';
import moment from 'moment';

const jsonNews = [
    {
        _id: 1,
        image: news1,
        title: "LOREM IPSUM DOLOR SIT AMET, CONSETETUR DIPSCING",
        description: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam.",
        date: "10",
        month: "jul",
        link: "/newsDetail"
    },
    {
        _id: 2,
        image: news2,
        title: "LOREM IPSUM DOLOR SIT AMET, CONSETETUR DIPSCING",
        description: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam.",
        date: "10",
        month: "jul",
        link: "/newsDetail"
    },
    {
        _id: 3,
        image: news3,
        title: "LOREM IPSUM DOLOR SIT AMET, CONSETETUR DIPSCING",
        description: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam.",
        date: "10",
        month: "jul",
        link: "/newsDetail"
    },
    {
        _id: 4,
        image: news3,
        title: "LOREM IPSUM DOLOR SIT AMET, CONSETETUR DIPSCING",
        description: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam.",
        date: "10",
        month: "jul",
        link: "/newsDetail"
    },
    {
        _id: 5,
        image: news3,
        title: "LOREM IPSUM DOLOR SIT AMET, CONSETETUR DIPSCING",
        description: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam.",
        date: "10",
        month: "jul",
        link: "/newsDetail"
    },
    {
        _id: 6,
        image: news3,
        title: "LOREM IPSUM DOLOR SIT AMET, CONSETETUR DIPSCING",
        description: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam.",
        date: "10",
        month: "jul",
        link: "/newsDetail"
    },
    {
        _id: 7,
        image: news3,
        title: "LOREM IPSUM DOLOR SIT AMET, CONSETETUR DIPSCING",
        description: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam.",
        date: "10",
        month: "jul",
        link: "/newsDetail"
    },
    {
        _id: 8,
        image: news3,
        title: "LOREM IPSUM DOLOR SIT AMET, CONSETETUR DIPSCING",
        description: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam.",
        date: "10",
        month: "jul",
        link: "/newsDetail"
    },
    {
        _id: 9,
        image: news3,
        title: "LOREM IPSUM DOLOR SIT AMET, CONSETETUR DIPSCING",
        description: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam.",
        date: "10",
        month: "jul",
        link: "/newsDetail"
    },
]


export class newsDetail extends Component {
    constructor(props){
        super(props);
        

        this.state = {
            items: []
        }
    }



    componentDidMount(){
        this.props.core.fetchNewsItem(this.props[0].match.params.id, (data) => {
            this.setState(data);
        })

        this.props.core.fetchLastNews((data) => {
            this.setState({items: data});
        })
    }


    componentDidUpdate(prevProps){
        if (prevProps[0].location.pathname != this.props[0].location.pathname){
            window.scrollTo(0,0);
            this.props.core.fetchNewsItem(this.props[0].match.params.id, (data) => {
                this.setState(data);
            })
    
            this.props.core.fetchLastNews((data) => {
                this.setState({items: data});
            })
    
        }
    }

    componentWillMount(){
        window.scroll(0,0);
    }

    render() {
        const newsDetail = true;
        return (
            <>
            <Container>
                     <div className="bredcrumb">
                        <h3>NOVOSTI</h3>

                        <p> <Link to="/">Početna</Link> / Novosti / <span>Detail</span></p>
                    </div>
                    </Container>
            <div className="newsDetail">
                <div className="wrapper">
                    <h6>NOVOSTI</h6>
                </div>

                <div className="content">
                    <Container>
                    <h2>{this.state.title}</h2>
                    <p><span>{moment.unix(this.state.time).format('DD. MMM YYYY.')} | {moment.unix(this.state.time).format('HH:mm')}h</span></p>


                    <Row>
                        <Col xl="8">
                    <p>{this.state.shortDescription}</p>

                    <img src={this.state.image} className="img-fluid detailImage"/>

                    <div dangerouslySetInnerHTML={{__html: this.state.contet}}>
                    </div>
                    </Col>
                    <Col xl="4" className="removePadding">
                        <h3>NAJNOVIJE</h3>
                   
                        <News items={this.state.items} newsDetail = {newsDetail}/>
                     

                    </Col>

                    </Row>
                    </Container>

                </div>

            </div>
            </>
        )
    }
}

export default DefaultLayout(newsDetail)
