import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import DefaultLayout from '../containers/defaultLayout';

import Navigation from '../containers/navbar.js';
import Footer from '../containers/footer.js';
import AccidentsMenu from '../containers/accidentsMenu.js';
import {
    Container, Row, Col, NavItem, NavLink
} from 'reactstrap';




export class accidents extends Component {

    componentWillMount(){
        window.scroll(0,0);
    }
    render() {

        return (
            <>
                <Container>
                    <div className="bredcrumb">
                        <h3>NEZGODE</h3>

                        <p> <Link to="/">Početna</Link> / Osiguranja / <span>Nezgode</span></p>
                    </div>
                </Container>
                <div className="accidents">
                    <div className="wrapper">
                        <h6>OSIGURANJA</h6>
                    </div>




                    <Container>
                        <Row>
                            <AccidentsMenu />

                            <Col xl="9" lg="9" md="8" xs="12">
                                <div className="content">
                                    <h2>NEZGODE</h2>

                                    <p>Osiguranjem od nezgode pokriveni su svi iznenadni i od volje osiguranika nezavisni događaji koji,
                                    djelujući uglavnom spolja, imaju za posljedicu njegovu smrt, invaliditet ili narušenje zdravlja.
                                    Ovo osiguranje obezbjeđuje isplatu ugovorene sume u slučaju smrti osiguranika ili dijela
                                    ugovorene sume u slučaju trajnog umanjenja radne sposobnosti osiguranika.
                                    Svako fizičko i pravno lice može ugovoriti osiguranje nezgode prema svojim željama i
                                    potrebama.
                                    Uslove osiguranja i visinu osiguranih suma sporazumno utvrđuju ugovarač osiguranja i
                                osiguravač.</p>
                                </div>


                            </Col>
                        </Row>
                    </Container>



                </div>

            </>

        )
    }
}

export default DefaultLayout(accidents)
