import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import Isvg from 'react-inlinesvg';
import ReactPaginate from 'react-paginate';

import DefaultLayout from '../containers/defaultLayout';




import News from '../containers/news.js'
import news1 from '../images/news/news1.png';
import news2 from '../images/news/news2.png';
import news3 from '../images/news/news3.png';

import {
    Container, Row, Col
} from 'reactstrap';

const jsonNews = [
    {
        _id: 1,
        image: news1,
        title: "LOREM IPSUM DOLOR SIT AMET, CONSETETUR DIPSCING",
        description: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam.",
        date: "10",
        month: "jul",
        link: "/newsDetail"
    },
    {
        _id: 2,
        image: news2,
        title: "LOREM IPSUM DOLOR SIT AMET, CONSETETUR DIPSCING",
        description: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam.",
        date: "10",
        month: "jul",
        link: "/newsDetail"
    },
    {
        _id: 3,
        image: news3,
        title: "LOREM IPSUM DOLOR SIT AMET, CONSETETUR DIPSCING",
        description: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam.",
        date: "10",
        month: "jul",
        link: "/newsDetail"
    },
    {
        _id: 4,
        image: news3,
        title: "LOREM IPSUM DOLOR SIT AMET, CONSETETUR DIPSCING",
        description: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam.",
        date: "10",
        month: "jul",
        link: "/newsDetail"
    },
    {
        _id: 5,
        image: news3,
        title: "LOREM IPSUM DOLOR SIT AMET, CONSETETUR DIPSCING",
        description: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam.",
        date: "10",
        month: "jul",
        link: "/newsDetail"
    },
    {
        _id: 6,
        image: news3,
        title: "LOREM IPSUM DOLOR SIT AMET, CONSETETUR DIPSCING",
        description: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam.",
        date: "10",
        month: "jul",
        link: "/newsDetail"
    },
    {
        _id: 7,
        image: news3,
        title: "LOREM IPSUM DOLOR SIT AMET, CONSETETUR DIPSCING",
        description: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam.",
        date: "10",
        month: "jul",
        link: "/newsDetail"
    },
    {
        _id: 8,
        image: news3,
        title: "LOREM IPSUM DOLOR SIT AMET, CONSETETUR DIPSCING",
        description: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam.",
        date: "10",
        month: "jul",
        link: "/newsDetail"
    },
    {
        _id: 9,
        image: news3,
        title: "LOREM IPSUM DOLOR SIT AMET, CONSETETUR DIPSCING",
        description: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam.",
        date: "10",
        month: "jul",
        link: "/newsDetail"
    },
]

export class news extends Component {
    constructor(props){
        super(props);
        this.fetchNews = this.fetchNews.bind(this);
        this.state = { width: 0, height: 0, items: [], page: 0, total: 0 };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);

     
    }

    fetchNews(){
        this.props.core.fetchNews(this.state.page * 6, 6, (data) => {
            this.setState({
                total: data.count / 6,
                items: data.result
            })
        })

    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);

        this.fetchNews();
    }

    componentDidUpdate(prevProps, prevState){
        if (prevState.page !== this.state.page){
            this.fetchNews();
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    componentWillMount(){
        window.scroll(0,0);
    }
    render() {
      const settings = {

            previousLabel: '',
            previousLinkClassName: 'no-ma',
            nextLabel: '',
            breakLabel: '...',
            breakClassName: 'break-me',
            pageCount: this.state.total,
            onPageChange: (page) => { this.setState({page: page.selected}) },
            marginPagesDisplayed: this.state.width <= 767 ? 2 : 3,
            pageRangeDisplayed: this.state.width <= 767 ? 1 : 2,
            pageClassName: 'all',
            pageLinkClassName: 'allLink',
            containerClassName: 'pagination',
            subContainerClassName: 'pages pagination',
            activeClassName: 'active'
        }
        return (

            <>

                <Container>
                    <div className="bredcrumb">
                        <h3>NOVOSTI</h3>

                        <p> <Link to="/">Početna</Link> / <span>Novosti</span></p>
                    </div>
                </Container>

                <div className="newsWrapper">
            

                    <Container>
                       

                        <News items={this.state.items} />

                        <div className="d-flex justify-content-end">
                        <ReactPaginate {...settings} />
                        </div>
                    </Container>
                </div>


            </>
        )
    }
}

export default DefaultLayout(news)
