import React, { Component } from 'react'
import AnimatedButton from '../../components/animatedButton';
import {Link} from 'react-router-dom';


import slide1 from '../../images/slider/slide1.png';

import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption,
  Container,
  Col,
  Row,
  Button
} from 'reactstrap';

const items = [
  {

    src: slide1,
    altText: 'Osiguranje nezgoda',
    caption: 'ZAŠTITITE SVOJE NAJBLIŽE',
    link: "/accidents"
  },
  {
    src: slide1,
    altText: 'Osiguranje nezgoda',
    caption: 'ZAŠTITITE SVOJE NAJBLIŽE',
    link: "/accidents"
  },
  {
    src: slide1,
    altText: 'Osiguranje nezgoda',
    caption: 'ZAŠTITITE SVOJE NAJBLIŽE',
    link: "/accidents"
  }
];

export class carousel extends Component {
  constructor(props) {
    super(props);
    this.state = { activeIndex: 0, items: [] };
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.goToIndex = this.goToIndex.bind(this);
    this.onExiting = this.onExiting.bind(this);
    this.onExited = this.onExited.bind(this);
  }


  componentDidMount(){
    this.props.core.fetchSlides((data) => {
      this.setState({
        items: data
      })
    } )
  }

  onExiting() {
    this.animating = true;
  }

  onExited() {
    this.animating = false;
  }

  next() {
    if (this.animating) return;
    const nextIndex = this.state.activeIndex === this.state.items.length - 1 ? 0 : this.state.activeIndex + 1;
    this.setState({ activeIndex: nextIndex });
  }

  previous() {
    if (this.animating) return;
    const nextIndex = this.state.activeIndex === 0 ? this.state.items.length - 1 : this.state.activeIndex - 1;
    this.setState({ activeIndex: nextIndex });
  }

  goToIndex(newIndex) {
    if (this.animating) return;
    this.setState({ activeIndex: newIndex });
  }

  render() {
    const { activeIndex } = this.state;
    const buttonText = "DETALJNIJE"
    const slides = this.state.items.map((item) => {
      return (
        <CarouselItem
          onExiting={this.onExiting}
          onExited={this.onExited}
          key={item.image}>


          <img src={item.image} alt={item.title} className="img-fluid" />

          <div className="overlay animated slideInLeft"></div>


          <div className="wrappSliderContent animated slideInLeft">
            <h2>{item.subtitle}</h2>
            <h1>{item.title}</h1>

         <a href={item.link}><AnimatedButton buttonText = {buttonText} /></a>   
          </div>


        </CarouselItem>
      );
    });

    return (
      <>
        <div className="wrapperCarousel">
          <Container fluid>
            <Carousel activeIndex={activeIndex} next={this.next} previous={this.previous}>
              <CarouselIndicators items={this.state.items} activeIndex={activeIndex} onClickHandler={this.goToIndex} />
              {slides}
              <CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous} />
              <CarouselControl direction="next" directionText="Next" onClickHandler={this.next} />
            </Carousel>
          </Container>
        </div>
      </>
    )
  }
}

export default carousel
