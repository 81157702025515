import React from 'react';
import {Link} from 'react-router-dom';
import Navigacija from './navbar';
import Footer from './footer';

export const DefaultLayout = (Wrapped) => (props) => {
        return(
            <div>
                <Navigacija {...props} about={props[0].location.pathname == "/about" ? true : false} 
                                       locations={props[0].location.pathname == "/AllLocations" ? true : false}
                                       news={props[0].location.pathname.indexOf('/news') !== -1 ? true : false}
                                       gallery={props[0].location.pathname == "/gallery" ? true : false}
                                       contact={props[0].location.pathname == "/contact" ? true : false}
                                       //newsDetail={props[0].location.pathname == "/newsDetail" ? true : false}
                                       property={props[0].location.pathname == "/property" ? true : false}
                                       opasnost={props[0].location.pathname == "/opasnost" ? true : false}
                                       provale={props[0].location.pathname == "/provale" ? true : false}
                                       lom={props[0].location.pathname == "/lom" ? true : false}
                                       domacinstva={props[0].location.pathname == "/stvariDomacinstvo" ? true : false}
                                       pozar={props[0].location.pathname == "/pozar" ? true : false}
                                       reports={props[0].location.pathname == "/reports" ? true : false}
                                       regulations={props[0].location.pathname == "/regulations" ? true : false}
                                       complaints={props[0].location.pathname == "/complaints" ? true : false}
                                       accidents={props[0].location.pathname == "/accidents" ? true : false}
                                       damage={props[0].location.pathname == "/damage" ? true : false}
                                       reportDamage={props[0].location.pathname == "/reportDamage" ? true : false}
                                       requiredDoc={props[0].location.pathname == "/requiredDoc" ? true : false}
                                       transport={props[0].location.pathname == "/transport" ? true : false}
                                       odgovornost={props[0].location.pathname == "/responsibility" ? true : false}
                                       healthy={props[0].location.pathname == "/healthy" ? true : false}
                                       authoutity={props[0].location.pathname == "/authoutity" ? true : false}
                                       kasko={props[0].location.pathname == "/kasko" ? true : false}
                                       insurancePassengers={props[0].location.pathname == "/insurancePassengers" ? true : false}
                                       accidentInsurance={props[0].location.pathname == "/accidentInsurance" ? true : false}
                                       combinedInsurance={props[0].location.pathname == "/combinedInsurance" ? true : false}
                                       collectiveInsurance={props[0].location.pathname == "/collectiveInsurance" ? true : false}
                                       studentsInsurance={props[0].location.pathname == "/studentsInsurance" ? true : false}
                                       guestInsurance={props[0].location.pathname == "/guestInsurance" ? true : false}
                                       sportsinsurance={props[0].location.pathname == "/sportsinsurance" ? true : false}
                                       detailLocation={props[0].location.pathname.indexOf('/detailLocation') !== -1 ? true : false}
                                       
                                       />
                <Wrapped {...props} />
                <Footer {...props}/>
            </div>
        )
}

export default DefaultLayout;