import React, { Component } from 'react'

export class djelatnost extends Component {
    render() {
        return (
            <div className="content">
                <h4>DJELATNOST</h4>

                <p>Osnovna djelatnost kompanije "NEŠKOVIĆ OSIGURANJE" AD jeste pružanje različitih vidova osiguranja:</p>
                <div className="text">
                    <h6>1. Osiguranje motornih vozila</h6>

                    -osiguranje od auto-odgovornosti
                    -kasko osiguranje vozila.
    
                <h6>2. Osiguranje lica od posljedica nesrećnog slučaja – nezgode</h6>

                    <li>-obavezno osiguranje putnika u javnom prevozu,</li>
                    <li> -osiguranje auto nezgode,</li>
                    <li> -kolektivno kombinovano osiguranje zaposlenih radnika,</li>
                    <li> -kolektivno osiguranje zaposlenih radnika,</li>
                    <li> -osiguranje učenika i studenata,</li>
                    <li> -osiguranje gostiju hotela, motela, kampova, banjskih liječilišta i slično,</li>
                    <li> -osiguranje članova sportskih organizacija,</li>
                    <li>-porodično osiguranje…</li>

                    <h6>3. Osiguranje imovine</h6>

                    <li>-osiguranje od opasnosti požara i nekih drugih opasnosti,</li>
                    <li> -osiguranje od loma mašina i nekih drugih opasnosti,</li>
                    <li>-osiguranje od provalne krađe i razbojništva,</li>
                    <li>-osiguranje stakla od loma,</li>
                    <li>-osiguranje stvari domaćinstva,</li>
                    <li>  -osiguranje objakata u izgradnji i montaži,</li>
                    <li>-osiguranje usled prekida rada (šomažno osiguranje), gubitak dohotka, nadoknada ličnih
                 zarada i sl.</li>
                    <li> -osiguranje odgovornosti iz djelatnosti,</li>
                    <li> -osiguranje usjeva i plodova,</li>
                    <li>-osiguranje životinja,</li>
                    <li>-osiguranje robe u domaćem i međunarodnom transportu.</li>

                    <h6>"NEŠKOVIĆ OSIGURANJE" AD kroz poslovnu saradnju sa preduzećem "NEŠKOVIĆ" DOO
                obezbjeđuje i:</h6>

                    <li> -provjeru ispravnosti Vašeg vozila na tehničkim pregledima lociranim širom Republike Srpske,</li>
                    <li> -licence "A" "B" "C" i "D" za motorna vozila,</li>
                    <li> -usluge baždarenja i popravke tahografa,</li>
                    <li> -zaključivanje ugovora o kupoprodaji motornih vozila,</li>
                    <li>-posredovanje u lizingu.</li>

                </div>
            </div>
        )
    }
}

export default djelatnost
