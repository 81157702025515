import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import Isvg from 'react-inlinesvg';

import LeftSideMenu from '../components/LeftMenu.js';
import drop_down from '../images/svg/drop_down.svg';
import { Col } from 'reactstrap';
const menuJson = [
    {
        _id: 1,
        text: "2018",
        link: "/reports"
    },
    {
        _id: 2,
        text: "2017",
        link: "#"
    },
    {
        _id: 3,
        text: "2016",
        link: "#"
    },
    {
        _id: 4,
        text: "2015",
        link: "#"
    }
    ,
    {
        _id: 5,
        text: "2014",
        link: "#"
    },
    {
        _id: 6,
        text: "2013",
        link: "#"
    },
    {
        _id: 7,
        text: "2012",
        link: "#"
    },
    {
        _id: 8,
        text: "2011",
        link: "#"
    },
    {
        _id: 9,
        text: "2010",
        link: "#"
    },
    {
        _id: 10,
        text: "2009",
        link: "#"
    },
    {
        _id: 11,
        text: "2008",
        link: "#"
    },
    {
        _id: 12,
        text: "2007",
        link: "#"
    }

]

export class reportsMenu extends Component {
    constructor(props) {
        super(props);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.state = {
            open: true
        }
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);

    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    render() {
        return (
            <>
                <Col xl="3" lg="3" md="4" xs="12">
                    <div className="propertyBox">
                        <div className="contentBox">
                            <div className="wrappButton">
                                <Link to="/reports"> <h2>IZVJEŠTAJI</h2></Link>
                                <button className="d-md-none" onClick={() => this.setState({ open: !this.state.open })}> <Isvg src={drop_down} /></button>

                            </div>

                            <div className= {this.state.width < 767 ?  this.state.open ? "zatvoren" : "otvoren"  : ""}>
                                <LeftSideMenu items={menuJson} />
                               
                        </div>
                        </div>
                    </div>
                </Col>
            </>
        )
    }
}

export default reportsMenu
