import React, { Component } from 'react'
import DefaultLayout from '../containers/defaultLayout';
import Isvg from 'react-inlinesvg';
import ReportsMenu from '../containers/reportsMenu.js';
import { Link } from 'react-router-dom';
import { Container, Row, Col, NavItem, NavLink } from 'reactstrap';

import downArrow from '../images/svg/down-arrow.svg';


export class reports extends Component {
    constructor(props) {
        super(props);

        this.state = {
            downloads: [],
            minYear: 2007,
            maxYear: new Date().getFullYear(),
            selected: new Date().getFullYear()

        }
    }

    componentDidMount() {

        this.props.core.fetchDownloads({ category: 'izvjestaji' }, (data) => {
            console.log(data);
            console.log(new Date().getFullYear());
            let min = new Date().getFullYear();
            let max = data.length ? data[0].year : new Date().getFullYear();
            for (let i = 0; i < data.length; i++) {
                if (data[i].year < min) {
                    min = data[i].year;
                }

                if (data[i].year > max) {
                    max = data[i].year;
                }
            }

            this.setState({
                downloads: data,
                maxYear: max,
                minYear: min,
                selected: max
            })
        })
    }
    componentWillMount(){
        window.scroll(0,0);
    }

    render() {

        let years = [];

        for (let i = this.state.maxYear; i >= this.state.minYear; i--) {
            years.push(i);
        }

        return (
            <div>
                <Container>
                    <div className="bredcrumb">
                        <h3>IZVJEŠTAJI</h3>

                        <p> <Link to="/">Početna</Link> / <span>Izvještaji</span></p>
                    </div>
                </Container>
                <div className="reports">

                    <div className="wrapper">
                        <h6>IZVJEŠTAJI</h6>
                    </div>

                    <Container>
                        <Row>
                            <Col xl="3" lg="3" md="4" xs="12">
                                <div className="propertyBox">
                                    <div className="contentBox">
                                        <Link to="/reports"> <h2>IZVJEŠTAJI</h2></Link>

                                        {

                                            years.map((item, idx) => {
                                                return (
                                                    <div key={idx}>
                                                        <NavItem onClick={() => this.setState({selected: item})}>
                                                            <NavLink className={this.state.selected == item ?  'activ' : null}>{item}</NavLink>
                                                        </NavItem>
                                                    </div>

                                                )
                                            })



                                        }
                                    </div>
                                </div>
                            </Col>


                            <Col xl="9">
                                {
                                    this.state.downloads.map((item, idx) => {
                                        if (item.year == this.state.selected)
                                        return (
                                            <div className="reportBox" key={idx}>
                                            <a target="_blank" href={item.file}><p>{item.name}</p></a>


                                            <a target="_blank" href={item.file}><Isvg src={downArrow} /></a>
                                        </div>
        
                                        )
                                    })
                                }
                                {/*
                                <div className="reportBox">
                                    <p>Dana 09.04.2019. godine Nešković osiguranje a.d. Bijeljina je na
                                    zvaničnoj internet stranici u okviru izvještaja objavilo Izvještaj o
                                    poslovanju za 2018. godinu i Revizorski izvještaj za 2018. godinu.</p>
                                    <Link to="#"><Isvg src={downArrow} /></Link>
                                </div>
                                <div className="reportBox">
                                    <p>Revizorski izvještaj za 2018 god. .</p>
                                    <Link to="#"><Isvg src={downArrow} /></Link>
                                </div>
                                <div className="reportBox">
                                    <p>Godišnji izvještaj o poslovanju za 2018 god. </p>
                                    <Link to="#"><Isvg src={downArrow} /></Link>
                                </div>
                                <div className="reportBox">
                                    <p>Bilans stanja 31.12.2018.god. </p>
                                    <Link to="#"><Isvg src={downArrow} /></Link>
                                </div>
                                <div className="reportBox">
                                    <p>Bilans uspjeha 31.12.2018.god. </p>
                                    <Link to="#"><Isvg src={downArrow} /></Link>
                                </div>
                                <div className="reportBox">
                                    <p>Revizorski izvještaj za 2018 god. </p>
                                    <Link to="#"><Isvg src={downArrow} /></Link>
                                </div>
                                <div className="reportBox">
                                    <p>Godišnji izvještaj o poslovanju za 2018 god. </p>
                                    <Link to="#"><Isvg src={downArrow} /></Link>
                                </div>
                                <div className="reportBox">
                                    <p>Bilans stanja 31.12.2018.god. </p>
                                    <Link to="#"><Isvg src={downArrow} /></Link>
                                </div>
                                <div className="reportBox">
                                    <p>Bilans uspjeha 31.12.2018.god.  </p>
                                    <Link to="#"><Isvg src={downArrow} /></Link>
                                </div>
                                */}


                            </Col>
                        </Row>
                    </Container>



                </div>
            </div >

        )
    }
}

export default DefaultLayout(reports)
