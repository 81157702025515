import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form'

import {
    Container, Row, Col, Button, FormGroup,
    Input, NavItem
} from 'reactstrap';
import AnimtedButton from '../../components/animatedButton.js';
import { renderField, renderSelectField } from './fields'

const required = value => value ? undefined : "Required"



const form = (props) => {
    const { handleSubmit, pristine, reset, submitting } = props;
    const buttonText = "POŠALJI";

    return (
        <form onSubmit={handleSubmit}>
            <Row>
                <Col xl="6" lg="6" md="6">
                    <FormGroup>
                        <Field validate={[required]} component={renderField} type="text" placeholder="Ime" name="firstName" />
                    </FormGroup>

                </Col>
                <Col xl="6" lg="6" md="6">
                    <FormGroup>
                        <Field validate={[required]} component={renderField} type="text" placeholder="Prezime" name="lastName"/>
                    </FormGroup>
                </Col>
                <Col xl="6" lg="6" md="6">
                    <FormGroup>
                        <Field validate={[required]} component={renderField} type="text" placeholder="Telefon" name="phone"/>
                    </FormGroup>
                </Col>
                <Col xl="6" lg="6" md="6">
                    <FormGroup>
                        <Field validate={[required]} component={renderField} type="text" placeholder="E-mail" name="email" />
                    </FormGroup>
                </Col>

                <Col xl="6" lg="6" md="6">
                    <FormGroup>
                        <Field validate={[required]} component={renderField} type="text" placeholder="Tema" name="subject" />
                    </FormGroup>
                </Col>






                <Col xl="12" lg="12" md="12">
                    <FormGroup>
                    <Field validate={[required]} component={renderField} type="textarea" placeholder="Poruka" name="message"/>
                    </FormGroup>
                </Col>
            </Row>

            <AnimtedButton buttonText={buttonText} />

        </form>
    )
}
export default reduxForm({
    form: 'form'  // a unique identifier for this form
})(form)
