import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { Col } from 'reactstrap';
import Isvg from 'react-inlinesvg';

import LeftSideMenu from '../components/LeftMenu.js';
import drop_down from '../images/svg/drop_down.svg';

const menuJson = [
    {
        _id: 1,
        text: "Osiguranje od požara i nekih drugih opasnosti",
        link: "/pozar"
    },
    {
        _id: 2,
        text: "Osiguranje mašina od loma i nekih drugih opasnosti",
        link: "/opasnost"
    },
    {
        _id: 3,
        text: "Osiguranje od provalne krađe i razbojništva",
        link: "/provale"
    },
    {
        _id: 4,
        text: "Osiguranje stakla od loma",
        link: "/lom"
    }
    ,
    {
        _id: 4,
        text: "Osiguranje stvari domaćinstva",
        link: "/stvariDomacinstvo"
    }

]


export class propertyMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: true,
            width: 0,
            height: 0
        }
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }


    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    render() {
        return (
            <>
                <Col xl="3" lg="3" md="4" xs="12">
                    <div className="propertyBox">
                        <div className="contentBox">
                            <div className="wrappButton">
                                <Link to="/property"> <h2>IMOVINA</h2></Link>
                                <button className="d-md-none" onClick={() => this.setState({ open: !this.state.open })}> <Isvg src={drop_down} /></button>
                            </div>

                            <div className={this.state.width < 767 ? this.state.open ? "zatvoren" : "otvoren" : ""}>
                                <LeftSideMenu items={menuJson} />

                            </div>
                        </div>
                    </div>
                </Col>

            </>
        )
    }
}

export default propertyMenu
