import React, { Component } from 'react'
import Isvg from 'react-inlinesvg';
import {Link} from 'react-router-dom';

import AnimtedButton from '../../components/animatedButton.js';



import auto from '../../images/svg/document/auto.svg';
import izvjestaji from '../../images/svg/document/izvjestaji.svg';
import propisi from '../../images/svg/document/propisi.svg';
import prituzbe from '../../images/svg/document/prituzbe.svg';

import {
    Container, Row, Button, Col
} from 'reactstrap';


export class document extends Component {
    render() {
        const buttonText = "DETALJNIJE"
        return (
            <div className="document">
                     <div className="wrapper">
                    <h6>PREUZIMANJA</h6>
                </div>
               <Container>
                   <h1>DOKUMENTA ZA PREUZIMANJE</h1>
                   <p></p>

                   <Row>
                       <Col xl="3" lg="3" md="3" xs="6">
                           <div className="box">
                               <Isvg src={auto}/>
                               <h3>ŠTETE</h3>

                                <Link to="/damage" ><AnimtedButton buttonText={buttonText} /></Link>
                           </div>
                       </Col>
                       <Col xl="3" lg="3" md="3" xs="6">
                           <div className="box">
                           <Isvg src={izvjestaji}/>
                               
                               <h3>IZVJEŠTAJI</h3>
                               <Link to="/reports" ><AnimtedButton buttonText={buttonText} /></Link>

                           </div>
                       </Col>
                       <Col xl="3" lg="3" md="3" xs="6">
                           <div className="box">
                           <Isvg src={propisi}/>

                               <h3>PROPISI</h3>
                               <Link to="/regulations" ><AnimtedButton buttonText={buttonText} /></Link>

                           </div>
                       </Col> 
                       <Col xl="3" lg="3" md="3" xs="6">
                           <div className="box ">

                           <Isvg src={prituzbe}/>

                               <h3>PRITUŽBE</h3>
                               <Link to="/complaints" ><AnimtedButton buttonText={buttonText} /></Link>

                           </div>
                       </Col>
                   </Row>
               </Container>
            </div>
        )
    }
}

export default document
