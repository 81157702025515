import React, { Component } from 'react'
import DefaultLayout from '../../containers/defaultLayout';
import { Link } from 'react-router-dom';

import AccidentsMenu from '../../containers/accidentsMenu.js';

import { Container, Row, Col } from 'reactstrap';


export class accidentInsurance extends Component {

    componentWillMount(){
        window.scroll(0,0);
    }
    render() {
        return (
            <>
                <Container>
                    <div className="bredcrumb">
                        <h3>NEZGODE</h3>

                        <p> <Link to="/">Početna</Link> / Osiguranja / <span> Osiguranje autonezgode</span></p>
                    </div>
                </Container>
                <div className="accidents">
                    <div className="wrapper">
                        <h6>OSIGURANJA</h6>
                    </div>




                    <Container>
                        <Row>
                            <AccidentsMenu />

                            <Col xl="9" lg="9" md="8" xs="12">
                                <div className="content">
                                    <h2>OSIGURANJE AUTONEZGODE</h2>

                                  <p>  Osiguranje autonezgode predstavlja dobrovoljno osiguranje vozača i putnika u motornom vozilu. Veoma je popularan vid zaštite osiguranika i često se ugovara u paketu sa osiguranjem od autoodgovornosti.</p>

                                 <p> <span>  Vozače i putnike osiguravamo uz vrlo povoljnu premiju za slučaj:</span>  </p>

                                   <li> - smrti uslijed nezgode i </li>
                                   <li> - invaliditeta uslijed nezgode.</li>
                                </div>


                            </Col>
                        </Row>
                    </Container>



                </div>

            </>

        )
    }
}

export default DefaultLayout(accidentInsurance)
