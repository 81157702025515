import React, { Component } from 'react';
import { Link } from 'react-router-dom';


import {
    Col, Button, Row
} from 'reactstrap';

const LocationJson = [{
    _id: 1,
    text: "Banja Luka",
    link: "/detailLocation",
    lang: "44.7371733",
    lat: "19.2030126"
},
{
    _id: 2,
    text: "Bijeljina",
    link: "/detailLocation",
    lang: "44.7371733",
    lat: "19.2030126"
},
{
    _id: 3,
    text: "Brčko",
    link: "/detailLocation",
    lang: "44.7371733",
    lat: "19.2030126"
},
{
    _id: 4,
    text: "Brod",
    link: "/detailLocation",
    lang: "44.7371733",
    lat: "19.2030126"
},

{
    _id: 5,
    text: "Trebinje",
    link: "/detailLocation",
    lang: "44.7371733",
    lat: "19.2030126"
},
{
    _id: 6,
    text: "Zvornik",
    link: "/detailLocation",
    lang: "44.7371733",
    lat: "19.2030126"
},
{
    _id: 7,
    text: "Prnjavor",
    link: "/detailLocation",
    lang: "44.7371733",
    lat: "19.2030126"
},
{
    _id: 8,
    text: "Foča",
    link: "/detailLocation",
    lang: "44.7371733",
    lat: "19.2030126"
},

{
    _id: 9,
    text: "Banja Luka",
    link: "/detailLocation",
    lang: "44.7371733",
    lat: "19.2030126"
},
{
    _id: 10,
    text: "Bijeljina",
    link: "/detailLocation",
    lang: "44.7371733",
    lat: "19.2030126"
},
{
    _id: 11,
    text: "Brčko",
    link: "/detailLocation",
    lang: "44.7371733",
    lat: "19.2030126"
},
{
    _id: 12,
    text: "Brod",
    link: "/detailLocation",
    lang: "44.7371733",
    lat: "19.2030126"
},
{
    _id: 13,
    text: "Laktaši",
    link: "/detailLocation",
    lang: "44.7371733",
    lat: "19.2030126"
},
{
    _id: 14,
    text: "Istočno Sarajevo",
    link: "/detailLocation",
    lang: "44.7371733",
    lat: "19.2030126"
},
{
    _id: 15,
    text: "Lopare",
    link: "/detailLocation",
    lang: "44.7371733",
    lat: "19.2030126"
},
{
    _id: 16,
    text: "Kotor Varoš",
    link: "/detailLocation",
    lang: "44.7371733",
    lat: "19.2030126"
},
{
    _id: 17,
    text: "Sokolac",
    link: "/detailLocation",
    lang: "44.7371733",
    lat: "19.2030126"
},
{
    _id: 18,
    text: "Modriča",
    link: "/detailLocation",
    lang: "44.7371733",
    lat: "19.2030126"
},
{
    _id: 19,
    text: "Gradiška",
    link: "/detailLocation",
    lang: "44.7371733",
    lat: "19.2030126"
},
{
    _id: 20,
    text: "Derventaš",
    link: "/detailLocation",
    lang: "44.7371733",
    lat: "19.2030126"
},
{
    _id: 21,
    text: "Novi Grad",
    link: "/detailLocation",
    lang: "44.7371733",
    lat: "19.2030126"
},
{
    _id: 22,
    text: "Teslić",
    link: "/detailLocation",
    lang: "44.7371733",
    lat: "19.2030126"
},
{
    _id: 23,
    text: "Kozarska Dubica",
    link: "/detailLocation",
    lang: "44.7371733",
    lat: "19.2030126"
},
{
    _id: 24,
    text: "Petrovo",
    link: "/detailLocation",
    lang: "44.7371733",
    lat: "19.2030126"
},
{
    _id: 25,
    text: "Nova Topola",
    link: "/detailLocation",
    lang: "44.7371733",
    lat: "19.2030126"
},
{
    _id: 26,
    text: "Ljubinje",
    link: "/detailLocation",
    lang: "44.7371733",
    lat: "19.2030126"
},
{
    _id: 27,
    text: "Višegrad",
    link: "/detailLocation",
    lang: "44.7371733",
    lat: "19.2030126"
},
{
    _id: 28,
    text: "Šamac",
    link: "/detailLocation",
    lang: "44.7371733",
    lat: "19.2030126"
},
{
    _id: 29,
    text: "Bratunac",
    link: "/detailLocation",
    lang: "44.7371733",
    lat: "19.2030126"
},
{
    _id: 30,
    text: "Bileća",
    link: "/detailLocation",
    lang: "44.7371733",
    lat: "19.2030126"
},
{
    _id: 31,
    text: "Kneževo",
    link: "/detailLocation",
    lang: "44.7371733",
    lat: "19.2030126"
},
{
    _id: 32,
    text: "Mrkonjić Grad",
    link: "/detailLocation",
    lang: "44.7371733",
    lat: "19.2030126"
},
{
    _id: 33,
    text: "Ugljevik",
    link: "/detailLocation",
    lang: "44.7371733",
    lat: "19.2030126"
},
{
    _id: 34,
    text: "Pale",
    link: "/detailLocation",
    lang: "44.7371733",
    lat: "19.2030126"
},
{
    _id: 35,
    text: "Bratunac",
    link: "/detailLocation",
    lang: "44.7371733",
    lat: "19.2030126"
},
{
    _id: 36,
    text: "Bileća",
    link: "/detailLocation",
    lang: "44.7371733",
    lat: "19.2030126"
},


{
    _id: 37,
    text: "Srbac",
    link: "/detailLocation",
    lang: "44.7371733",
    lat: "19.2030126"
},
{
    _id: 38,
    text: "Rogatica",
    link: "/detailLocation",
    lang: "44.7371733",
    lat: "19.2030126"
},
{
    _id: 39,
    text: "Doboj",
    link: "/detailLocation",
    lang: "44.7371733",
    lat: "19.2030126"
},
{
    _id: 40,
    text: "Prijedor",
    link: "/detailLocation",
    lang: "44.7371733",
    lat: "19.2030126"
},

{
    _id: 41,
    text: "Nevesinje",
    link: "/detailLocation",
    lang: "44.7371733",
    lat: "19.2030126"
},
]


export class allLocationsComponent extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        const item = this.props.cities.map((item, idx) => {

            return (
                


                <Col xl="3" lg="3" md="3" xs="6" key={idx}><Link to={`/detailLocation/${idx}/${item.name.toLowerCase().replace(/ /g, '-')}`}>  <Button> {item.name}</Button></Link></Col>
            )

        });
        return (
            <div className="wrappButton">
            <Row>

                {item} 
            </Row>
            </div>
        )
    }
}

export default allLocationsComponent
