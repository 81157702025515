

import React, { Component } from 'react'
import { Link } from 'react-router-dom';


import Navigation from '../../containers/navbar.js';
import Footer from '../../containers/footer.js';

import PropertyMenu from '../../containers/propertyMenu.js';
import DefaultLayout from '../../containers/defaultLayout.js';

import {
    Container, Row, Col
} from 'reactstrap';




export class Opasnost extends Component {
    constructor(props) {
        super(props);

        this.state = {
            menu: {}
        }
    }

    componentWillMount(){
        window.scroll(0,0);
    }
    render() {

        return (
            <div>
                <Container>
                    <div className="bredcrumb">
                        <h3>IMOVINA</h3>
                        <p> <Link to="/">Početna</Link> / Osiguranja / Imovina  / <span>Osiguranje mašina od loma i nekih drugih opasnosti</span></p>
                    </div>
                </Container>


                <div className="property">
                    <div className="wrapper">
                        <h6>OSIGURANJA</h6>
                    </div>
                    <Container>
                        <Row>

                            <PropertyMenu />

                            <Col xl="9" lg="9" md="8" xs="12">
                                <div className="domacinstvo">
                                    <h2>OSIGURANJE MAŠINA OD LOMA I NEKIH
           DRUGIH OPASNOSTI</h2>

                                    <p>  Osiguranje mašina od loma i nekih drugih opasnosti pruža zaštitu od oštećenja ili uništenja do
                                      kojeg može doći uslijed nezgode u pogonu, uslijed nespretnosti, nehata ili zle namjere radnika ili
                                      nekog drugog lica. Pod nezgodom u pogonu se podrazumijevaju svi događaji koji nastanu
           nepredviđeno i iznenada a u vezi su sa korišćenjem osigurane stvari.</p>

                                    <p> Predmet osiguiranja mogu biti: mašine, mašinski uređaji, električni uređaji i njihovo punjenje,
                                    ukoliko je vrijednost punjenja sadržana u vrijednosti osigurane stvari, aparati i instalacije
                                    zajedno sa postoljem, ležištem i temeljem, ukoliko je njihova vrijednost sadržana u vrijednosti
           mašina.</p>

                                    <p> Ako se posebno ugovori predmet osiguranja mogu biti i: dalekovodi, kablovski vodovi visokog
                                    napona, gasovodi, naftovodi, toplovodi, vodovodna i kanalizaciona mreža, instalacije centralnog
           grijanja, elektronski računari…</p>

                                    <p> U slučaju štete na osiguranim stvarima nadoknađuje se vrijednost osigurane stvari ukoliko
           je uništena, dok se u slučaju oštećenja nadoknađuju troškovi njene popravke.</p>

                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>



            </div>
        )
    }
}

export default DefaultLayout(Opasnost)
















