import React, { Component } from 'react'
import Isvg from 'react-inlinesvg';
import { Link } from 'react-router-dom';
import { Col } from 'reactstrap';
import LeftSideMenu from '../components/LeftMenu.js';
import drop_down from '../images/svg/drop_down.svg';



const menuJson = [
    {
        _id: 1,
        text: "Izvod iz pravilnika",
        link: "/damage"
    },
    {
        _id: 2,
        text: "Gdje prijaviti štetu",
        link: "/reportDamage"
    },
    {
        _id: 3,
        text: "Minimalna potrebna dokumntacija prilikom prijave štete",
        link: "/requiredDoc"
    }


]

export class damageMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            width: 0,
            height: 0,
            open: true

        }
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }
    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }


    render() {


        return (
            <>
                <Col xl="3" lg="3" md="4" xs="12">
                    <div className="propertyBox">

                        <div className="contentBox">

                            <div className="wrappButton">
                                <Link to="#"> <h2>ŠTETE</h2></Link>
                                <button className="d-md-none" onClick={() => this.setState({ open: !this.state.open })}> <Isvg src={drop_down} /></button>
                            </div>

                            <div className={this.state.width < 767 ? this.state.open ? "zatvoren" : "otvoren" : ""}>

                                <LeftSideMenu items={menuJson} />

                            </div>

                        </div>
                    </div>
                </Col>
            </>

        )
    }
}

export default damageMenu
