import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import Isvg from 'react-inlinesvg';
import DefaultLayout from '../containers/defaultLayout';


import {
    Container,
    Row,
    Col,
    Image,
    Button,
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators
} from 'reactstrap';



import Slide1 from '../images/sliderGallery/slide1.png';
import Slide2 from '../images/sliderGallery/slide2.png';
import lupa from '../images/sliderGallery/lupa.png';



const pictures = [
    {
        src: Slide1,
        altText: 'Picture1',
        caption: 'Picture1'
    },
    {
        src: Slide2,
        altText: 'Picture2',
        caption: 'Picture2'
    },
    {
        src: Slide1,
        altText: 'Picture3',
        caption: 'Picture3'
    },
    {
        src: Slide2,
        altText: 'Picture3',
        caption: 'Picture3'
    },
    {
        src: Slide1,
        altText: 'Picture3',
        caption: 'Picture3'
    },
    {
        src: Slide2,
        altText: 'Picture3',
        caption: 'Picture3'
    },
];



export class gallery extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };

        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.goToIndex = this.goToIndex.bind(this);
        this.onExiting = this.onExiting.bind(this);
        this.onExited = this.onExited.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    onExiting() {
        this.animating = true;
    }

    onExited() {
        this.animating = false;
    }

    next() {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === this.props.gallery.length - 1 ? 0 : this.state.activeIndex + 1;
        this.setState({ activeIndex: nextIndex });
    }

    previous() {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === 0 ? this.props.gallery.length - 1 : this.state.activeIndex - 1;
        this.setState({ activeIndex: nextIndex });
    }

    goToIndex(newIndex) {
        if (this.animating) return;
        this.setState({ activeIndex: newIndex });
    }

    render() {
 

        const activeIndex = this.state.activeIndex;
        const slides = this.props.gallery.map((item) => {
            return (
                <CarouselItem className="bg"
                    onExiting={this.onExiting}
                    onExited={this.onExited}
                    key={item}
                >
                    <img src={item} className="img-fluid" />
                    <div className="carousel-caption">
                        <div className="container">
                            <div className="row d-flex justify-content-start">
                                <div className="col-xl-8 col-lg-12 col-md-12 col-sm-12 col-12">
                                </div>
                            </div>
                        </div>
                    </div>
                </CarouselItem>
            )
        });



        return (
            <>
          
                <Container>
                    <div className="bredcrumb">
                        <h3>GALERIJA</h3>

                        <p> <Link to="/">Početna</Link> / <span>Galerija</span></p>
                    </div>
                </Container>


                <div className="wrapGellery">


                    <div className="wrapper">
                        <h6>GALERIJA</h6>
                    </div>



                    <div className="galleryContent">
                        <Container>
                            <h2>GALERIJA NAŠIH POSLOVNICA</h2>
                            <p>Galerija naših poslovnica širom Republike Srpske.</p>

                            <Row>
                                {
                                    this.props.gallery.map((item, idx) => {
                                        return (
                                            <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                                            <div className="pictures" onClick={() => this.setState({ opened: true, activeIndex: idx })} >
                                                <img src={item} className="img-fluid image" />
                                                <div className="overlay">
                                                    <img src={lupa} />
                                                </div>
                                            </div>
                                        </Col>
        
                                        )
                                    })
                                }

                                {this.state.opened ?
                                    <div className="lightbox">
                                        <Container>
                                            <Row>
                                                <i className="fa fa-times" aria-hidden="true" onClick={() => this.setState({ opened: false })}></i>

                                                <Carousel className="bg"
                                                    activeIndex={activeIndex}
                                                    next={this.next}
                                                    previous={this.previous}
                                                >
                                                    <CarouselIndicators items={this.props.gallery} activeIndex={activeIndex} onClickHandler={this.goToIndex} />
                                                    {slides}
                                                    <i className="fa fa-angle-left" onClick={this.previous}></i>
                                                    <i className="fa fa-angle-right" onClick={this.next}></i>
                                                </Carousel>
                                            </Row>
                                        </Container>
                                    </div> : null
                                }
                            </Row>


                        </Container>
                    </div>

                </div>
            </>
        )
    }
}

export default DefaultLayout(gallery)
