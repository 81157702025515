import socketIO from 'socket.io-client';

const API_KEY = '5d4203d521026b4704dccd34';

export class Core {
    constructor(props) {
        this.socket = socketIO('https://socket.hugemedia.online');

    }




    fetchLastNews(callback) {
        this.socket.emit('apiCall', {
            apiKey: API_KEY,
            apiName: 'fetchLastNews',
            query: {},
            limit: 3,
            sort: {_id: -1}

        });

        this.socket.once('fetchLastNews', callback);
    }


    fetchNews(skip, limit, callback) {
        this.socket.emit('apiCall', {
            apiKey: API_KEY,
            apiName: 'fetchNews',
            query: {},
            limit: limit,
            skip: skip,
            sort: {_id: -1}
        });

        this.socket.once('fetchNews', callback);
    }
  
    fetchNewsItem(id, callback) {
        this.socket.emit('apiCall', {
            apiKey: API_KEY,
            apiName: 'fetchNewsItem',
            query: { _id: id },
        });

        this.socket.once('fetchNewsItem', callback);
    }

    fetchDownloads(query, callback) {
        this.socket.emit('apiCall', {
            apiKey: API_KEY,
            apiName: 'fetchDownloads',
            query: query,
        });

        this.socket.once('fetchDownloads', callback);
    }

    fetchServices(callback) {
        this.socket.emit('apiCall', {
            apiKey: API_KEY,
            apiName: 'fetchServices',
            query: {},
        });

        this.socket.once('fetchServices', callback);
    }
    fetchAboutUs(callback) {
        this.socket.emit('apiCall', {
            apiKey: API_KEY,
            apiName: 'fetchAboutUs',
            query: {},
        });

        this.socket.once('fetchAboutUs', callback);
    }

    fetchServiceItem(id, callback) {
        this.socket.emit('apiCall', {
            apiKey: API_KEY,
            apiName: 'fetchServiceItem',
            query: { _id: id },
        });

        this.socket.once('fetchServiceItem', callback);
    }

    fetchContactPersons(callback) {
        this.socket.emit('apiCall', {
            apiKey: API_KEY,
            apiName: 'fetchContactPersons',
            query: {},
        });

        this.socket.once('fetchContactPersons', callback);
    }
    fetchCities(callback) {
        this.socket.emit('apiCall', {
            apiKey: API_KEY,
            apiName: 'fetchCities',
            query: {},
        });

        this.socket.once('fetchCities', callback);
    }
    fetchOffices(callback) {
        this.socket.emit('apiCall', {
            apiKey: API_KEY,
            apiName: 'fetchOffices',
            query: {},
        });

        this.socket.once('fetchOffices', callback);
    }
    
    fetchGallery(callback) {
        this.socket.emit('apiCall', {
            apiKey: API_KEY,
            apiName: 'fetchGallery',
            query: {},
        });

        this.socket.once('fetchGallery', callback);
    }
    fetchSlides(callback) {
        this.socket.emit('apiCall', {
            apiKey: API_KEY,
            apiName: 'fetchSlides',
            query: {},
        });

        this.socket.once('fetchSlides', callback);
    }
    fetchTexts(callback) {
        this.socket.emit('apiCall', {
            apiKey: API_KEY,
            apiName: 'fetchTexts',
            query: {},
        });

        this.socket.once('fetchTexts', callback);
    }



    contact(data, callback) {
        this.socket.emit('apiCall', {
            apiKey: API_KEY,
            apiName: 'contact',
            query: {},
            data: data
        });

        this.socket.once('contact', callback);
    }



    
}