import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import DefaultLayout from '../../containers/defaultLayout';
import DamageMenu from '../../containers/damageMenu.js'
import { Container, Row, Col } from 'reactstrap';
import Isvg from 'react-inlinesvg';


import arrowRight from '../../images/svg/arrowRight.svg';


export class reportDamage extends Component {

    componentWillMount(){
        window.scroll(0,0);
    }
    render() {
        return (
            <>
                <Container>
                    <div className="bredcrumb">
                        <h3>ŠTETE</h3>

                        <p> <Link to="/">Početna</Link> / Štete  / <span>Izvod iz pravilnika</span></p>
                    </div>
                </Container>

                <div className="damage">
                    <div className="wrapper">
                        <h6>ŠTETE</h6>
                    </div>


                    <Container>
                        <Row>
                            <DamageMenu />

                            <Col xl="9" lg="9" md="8" xs="12">
                                <div className="damageContent reportDamage">

                                    <h2>IZVOD IZ PRAVILNIKA</h2>



                                    <div className="reportBox">
                                        <Link to="/contact">
                                            <p>Kontakt</p>
                                            <Isvg src={arrowRight} />
                                        </Link>
                                    </div>


                                    <div className="reportBox">
                                    <Link to="/AllLocations">
                                        <p>Naše poslovnice širom RS</p>
                                        <Isvg src={arrowRight} />
                                    </Link>
                                </div>
                                </div>

                              


                            </Col>
                        </Row>
                    </Container>
                </div>
            </>
        )
    }
}

export default DefaultLayout(reportDamage)
