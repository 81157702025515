import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import Isvg from 'react-inlinesvg';
import { NavLink as RRNavLink } from 'react-router-dom';
import OutsideClickHandler from 'react-outside-click-handler';
import nav from '../images/nav.png';

import poslovnce from '../images/NavbarImages/poslovnice.png';
import property from '../images/NavbarImages/property.png';
import pozar from '../images/NavbarImages/pozar.png';
import opasnost from '../images/NavbarImages/opasnost.png';
import provale from '../images/NavbarImages/provale.png';
import lom from '../images/NavbarImages/lom.png';
import domacinstva from '../images/NavbarImages/domacinstva.png';
import accidents from '../images/NavbarImages/accidents.png';
import kontakt from '../images/NavbarImages/kontakt.png';
import transport from '../images/NavbarImages/transport.png';
import odgovornost from '../images/NavbarImages/odgovornost.png';
import healthy from '../images/NavbarImages/healthy.png';
import authoutity from '../images/NavbarImages/authoutity.png';
import insurancePassengers from '../images/NavbarImages/insurancePassengers.png';
import accidentInsurance from '../images/NavbarImages/accidentInsurance.png';
import combinedInsurance from '../images/NavbarImages/combinedInsurance.png';
import studentsInsurance from '../images/NavbarImages/studentsInsurance.png';
import guestInsurance from '../images/NavbarImages/guestInsurance.png';
import sportsinsurance from '../images/NavbarImages/sportsinsurance.png';

import arrowRight from '../images/svg/arrow-right.svg';
import arrowLeft from '../images/svg/arrow-left.svg';


import {
    Container,
    Row, Col
    , Button, NavItem,
    NavLink, DropdownItem,
    DropdownMenu, DropdownToggle,
    UncontrolledDropdown, Nav, Collapse,
    NavbarToggler, NavbarBrand, Navbar
} from 'reactstrap';




import phone from '../images/svg/phone.svg';
import email from '../images/svg/email.svg';
import insta from '../images/svg/insta.svg';
import face from '../images/svg/face.svg';
import Close from '../images/svg/Close.svg';
import logo from '../images/svg/logo.svg';
import hamburger from '../images/svg/hamburger.svg';

import contact from '../images/svg/contact.svg';
import location from '../images/svg/location.svg';


export class navbar extends Component {
    constructor(props) {
        super(props);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);

        this.state = {
            width: 0,
            height: 0,
            isOpen: false,
            dropDownMenu: false,
            dropDownMenu2: false,
            otvoren: false, submenu: false, submenu1: false, submenu2: false, submenu3: false, submenu4: false, submenu5: false, submenu6: false, submenu7: false, submenu8: false


        };


    }


    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }




    funkcija(lokacije) {
        if (lokacije === "/") {
            this.setState({ submenu: true })
        }
        if (lokacije === "/authoutity" || lokacije == "/responsibility" || lokacije == "/kasko" || lokacije == "/healthy" || lokacije == "/transport") {
            this.setState({ submenu: false, submenu2: true })
        }
        if (lokacije == "/property" || lokacije == "/pozar" || lokacije == "/opasnost" || lokacije == "/provale" || lokacije == "/lom" || lokacije == "/stvariDomacinstvo") {
            this.setState({ submenu4: true })
        }
        if (lokacije == "/accidents" || lokacije == "/insurancePassengers" || lokacije == "/accidentInsurance" || lokacije == "/combinedInsurance" || lokacije == "/collectiveInsurance" || lokacije == "/studentsInsurance" || lokacije == "/guestInsurance" || lokacije == "/sportsinsurance") {
            this.setState({ submenu5: true })
        }
        if (lokacije == "/reports" || lokacije == "/regulations" || lokacije == "/complaints") {
            this.setState({ submenu6: true })
        }
    }

    render() {
        const location = this.props[0].location.pathname;
        return (
            <>

                <div className="wrapperNavbar">

                    <div className="header">
                        <Container fluid>
                            <Row>
                                <Col xl="6" lg="6" md="9" xs="12">
                                    <div className="wrappInfo">
                                        <div className="info">
                                            <p> <Isvg src={phone} /> 00387 55 294 310</p>

                                            <p><Isvg src={phone} />00387 55 294 314</p>

                                            <p><Isvg src={email} />info@neskovicosiguranje.com</p>
                                            <div></div>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl="6" lg="6" md="3" xs="12">
                                    <div className="socialIcon d-none d-md-flex">

                                        <Isvg src={insta} />
                                        <Isvg src={face} />

                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>

                    {this.state.width > 991 ?

                        <div className="navWrapp">
                            <Container fluid>
                                <Row className="">

                                    <Col xl="3" lg="2" xs="4" md="2">
                                        <NavbarBrand className="logo"><Isvg src={logo} /></NavbarBrand>
                                    </Col>

                                    <Col xl="9" lg="10" xs="8" md="10" className="colWrapperNav">
                                        <button className="dugme  d-lg-none d-block" onClick={() => this.setState({ isOpen: !this.state.isOpen })}>{this.state.isOpen ? <Isvg src={Close} /> : <Isvg src={hamburger} />}</button>





                                        <div className="navbar navbar-expand-lg">
                                            <Collapse isOpen={this.state.isOpen} navbar>

                                                <Nav className="d-block d-lg-flex justify-content-end">
                                                    <NavItem>
                                                        <NavLink to="/" exact tag={RRNavLink} activeClassName="active">POČETNA</NavLink>
                                                    </NavItem>
                                                    <NavItem>
                                                        <NavLink to="/about" exact tag={RRNavLink} activeClassName="active">O NAMA</NavLink>
                                                    </NavItem>
                                                    <NavItem>
                                                        <NavLink to="/AllLocations" className={this.props.detailLocation ? "active" : ""} exact tag={RRNavLink} activeClassName="active">POSLOVNICE</NavLink>

                                                    </NavItem>

                                                    <NavItem onClick={() => this.setState({ dropDownMenu2: !this.state.dropDownMenu2 })}>
                                                        <NavLink to="#" exact tag={RRNavLink} activeClassName="active">OSIGURANJA</NavLink>
                                                    </NavItem>

                                                    <NavItem>
                                                        <NavLink to="/damage" exact tag={RRNavLink} className={this.props.reportDamage || this.props.requiredDoc ? "active" : ""} activeClassName="active">ŠTETE</NavLink>
                                                    </NavItem>
                                                    <NavItem>
                                                        <NavLink to="/news" className={this.props.newsDetail ? "active" : ""} exact tag={RRNavLink} activeClassName="active">NOVOSTI</NavLink>
                                                    </NavItem>
                                                    <NavItem>
                                                        <NavLink to="/gallery" exact tag={RRNavLink} activeClassName="active">GALERIJA</NavLink>
                                                    </NavItem>
                                                    <NavItem onClick={() => this.setState({ dropDownMenu: !this.state.dropDownMenu })}>

                                                        <NavLink to="#" exact tag={RRNavLink} className={this.props.reports || this.props.regulations || this.props.complaints ? "active" : ""}>PREUZIMANJA</NavLink>

                                                        {this.state.dropDownMenu ?
                                                            <OutsideClickHandler
                                                                onOutsideClick={() => {
                                                                    this.setState({ dropDownMenu: false });
                                                                }}>

                                                                <div className="submenu2">
                                                                    <NavItem>
                                                                        <NavLink to="/reports" exact tag={RRNavLink} activeClassName="active">Izvještaji</NavLink>
                                                                    </NavItem>
                                                                    <NavItem>
                                                                        <NavLink to="/regulations" exact tag={RRNavLink} activeClassName="active">Propisi</NavLink>
                                                                    </NavItem>
                                                                    <NavItem>
                                                                        <NavLink to="/complaints" exact tag={RRNavLink} activeClassName="active">Pritužbe</NavLink>
                                                                    </NavItem>
                                                                </div>
                                                            </OutsideClickHandler>
                                                            : null}

                                                    </NavItem>
                                                    <NavItem>
                                                        <NavLink to="/contact" exact tag={RRNavLink} activeClassName="active">KONTAKT</NavLink>
                                                    </NavItem>
                                                </Nav>
                                            </Collapse>
                                        </div>


                                    </Col>
                                </Row>
                            </Container>
                            {this.state.dropDownMenu2 ?
                                <OutsideClickHandler
                                    onOutsideClick={() => {
                                        this.setState({ dropDownMenu2: false });
                                    }}>
                                    <div className="wrappSubMenu">
                                        <Container fluid>

                                            <div className="submenu">
                                                <Row>

                                                    <Col xl="2" lg="3">

                                                        <NavItem onClick={() => this.setState({ dropDownMenu2: false })}>
                                                            <NavLink to={`/osiguranje/${this.props.services && this.props.services[0] && this.props.services[0]._id}/${this.props.services && this.props.services[0] && this.props.services[0].name && this.props.services[0].name.toLowerCase().replace(/ /g, '-')}`} exact tag={RRNavLink} activeClassName="active"><h2>{this.props.services && this.props.services[0] && this.props.services[0].name}</h2></NavLink>
                                                        </NavItem>

                                                        {
                                                            this.props.services.map((item, idx) => {
                                                                if (item.parent == (this.props.services && this.props.services[0] && this.props.services[0]._id)) {
                                                                    return (
                                                                        <NavItem key={idx} onClick={() => this.setState({ dropDownMenu2: false })}>
                                                                            <NavLink to={`/osiguranje/${item._id}/${item.name.toLowerCase().replace(/ /g, '-')}`} exact tag={RRNavLink} activeClassName="active">{item.name && item.name.toLowerCase()}</NavLink>
                                                                        </NavItem>

                                                                    )
                                                                }
                                                            })
                                                        }

                                                    </Col>

                                                    <Col xl="2" lg="3" className="offset-xl-1">

                                                        <NavItem onClick={() => this.setState({ dropDownMenu2: false })}>
                                                            <NavLink to={`/osiguranje/${this.props.services && this.props.services[1] && this.props.services[1]._id}/${this.props.services && this.props.services[1] && this.props.services[1].name && this.props.services[1].name.toLowerCase().replace(/ /g, '-')}`} exact tag={RRNavLink} activeClassName="active"><h2>{this.props.services && this.props.services[1] && this.props.services[1].name}</h2></NavLink>
                                                        </NavItem>

                                                        {
                                                            this.props.services.map((item, idx) => {
                                                                if (item.parent == (this.props.services && this.props.services[1] && this.props.services[1]._id)) {
                                                                    return (
                                                                        <NavItem key={idx} onClick={() => this.setState({ dropDownMenu2: false })}>
                                                                            <NavLink to={`/osiguranje/${item._id}/${item.name.toLowerCase().replace(/ /g, '-')}`} exact tag={RRNavLink} activeClassName="active">{item.name && item.name.toLowerCase()}</NavLink>
                                                                        </NavItem>

                                                                    )
                                                                }
                                                            })
                                                        }

                                                    </Col>

                                                    <Col xl="5" lg="8" className="offset-xl-1">
                                                        <Row>
                                                            {
                                                                this.props.services.map((item, idx) => {
                                                                    if (idx == 0 || idx == 1 || item.parent)
                                                                        return null

                                                                    return (
                                                                        <Col xl="5" lg="5" className="offset-xl-1">

                                                                            <NavItem onClick={() => this.setState({ dropDownMenu2: false })}>
                                                                                <NavLink to={`/osiguranje/${item._id}/${item.name.toLowerCase().replace(/ /g, '-')}`} exact tag={RRNavLink} activeClassName="active">   <h2 className="special-heading">{item.name}</h2></NavLink>
                                                                            </NavItem>
                                                                        </Col>

                                                                    )
                                                                })
                                                            }
                                                        </Row>
                                                    </Col>


                                                </Row>
                                            </div>

                                        </Container>
                                    </div>
                                </OutsideClickHandler>

                                : null}
                        </div>


                        : <>

                            <Container >
                                <Row>

                                    <Col xs="3" md="2" lg="2" style={{ zIndex: 1 }}>
                                        <div className="logo2">
                                            <Link to="/"><Isvg src={logo} /></Link>
                                        </div>
                                    </Col>
                                    <Col xs="8" md="9" lg="10" style={{ zIndex: 1 }}>
                                        <button className="btn-menu" onClick={() => this.setState({ otvoren: !this.state.otvoren }, this.funkcija(location))}><Isvg src={hamburger} /></button>
                                    </Col>
                                </Row>
                            </Container>
                            <div className={this.state.otvoren ? 'navbarResposniveOpen2' : 'navbarResposnive2'}>
                                <div className={this.state.otvoren ? 'sadrzajOpen2' : 'sadrzaj2'} >
                                    <button onClick={() => this.setState({ otvoren: !this.state.otvoren, submenu: false })} className="closeIcon"><Isvg src={Close}></Isvg></button>
                                    <div style={{ overflow: 'hidden' }}>
                                        <ul className={this.state.submenu ? "content" : "content"}>
                                            <li><Link to='/' className="nav-link">POČETNA</Link></li>


                                            <li> <Link to="/about" className={location === "/about" ? "nav-link active" : "nav-link"}>O NAMA</Link> </li>


                                            <li> <Link to="/AllLocations" className={location === "/AllLocations" ? "nav-link active" : "nav-link"}>POSLOVNICE</Link> </li>


                                            <button onClick={() => this.setState({ submenu2: !this.state.submenu2 })} className="arrowRight">OSIGURANJA<Isvg src={arrowLeft}></Isvg></button>
                                            <li> <Link to="/damage" className={location === "/damage" ? "nav-link active" : "nav-link"}>ŠTETE</Link> </li>


                                            <li> <Link to="/news" className={location === "/news" ? "nav-link active" : "nav-link"}>NOVOSTI</Link> </li>


                                            <li> <Link to="/gallery" className={location === "/gallery" ? "nav-link active" : "nav-link"}>GALERIJA</Link> </li>
                                            <button onClick={() => this.setState({ submenu6: !this.state.submenu6 })} className="arrowRight">PREUZIMANJA<Isvg src={arrowLeft}></Isvg></button>
                                            <li> <Link to="/contact" className={location === "/contact" ? "nav-link active" : "nav-link"}>KONTAKT</Link> </li>
                                        </ul>
                                    </div>




                                    <div style={{ overflow: 'hidden' }}>
                                        <ul className={this.state.submenu2 ? 'content2' : 'noContent2'}>
                                            <button onClick={() => this.setState({ submenu2: !this.state.submenu2, })} className="arrowLeft"><Isvg src={arrowRight}></Isvg></button>


                                            <button onClick={() => this.setState({ submenu4: !this.state.submenu4 })} className="arrowRight">IMOVINA<Isvg src={arrowLeft}></Isvg></button>

                                            <button onClick={() => this.setState({ submenu5: !this.state.submenu5 })} className="arrowRight">NEZGODE<Isvg src={arrowLeft}></Isvg></button>

                                            {
                                                this.props.services.map((item, idx) => {
                                                    if (idx == 0 || idx == 1 || item.parent)
                                                        return null
                                                    return (
                                                        <li><Link className={location === `/osiguranje/${item._id}/${item.name.toLowerCase().replace(/ /g, '-')}` ? "nav-link active" : "nav-link"} to={`/osiguranje/${item._id}/${item.name.toLowerCase().replace(/ /g, '-')}`} className={location === "/authoutity" ? "nav-link active" : "nav-link"}>{item.name}</Link></li>
                                                    )

                                                })
                                            }
                                        </ul>
                                    </div>


                                    <div style={{ overflow: 'hidden' }}>
                                        <ul className={this.state.submenu4 ? 'content2' : 'noContent2'}>
                                            <button onClick={() => this.setState({ submenu4: !this.state.submenu4, submenu: true })} className="arrowLeft"><Isvg src={arrowRight}></Isvg></button>
                                            <li><Link to={`/osiguranje/${this.props.services && this.props.services[0] && this.props.services[0]._id}/${this.props.services && this.props.services[0] && this.props.services[0].name && this.props.services[0].name.toLowerCase().replace(/ /g, '-')}`} className={location === `/osiguranje/${this.props.services && this.props.services[0] && this.props.services[0]._id}/${this.props.services && this.props.services[0] && this.props.services[0].name && this.props.services[0].name.toLowerCase().replace(/ /g, '-')}` ? "nav-link active" : "nav-link"}>{this.props.services && this.props.services[0] && this.props.services[0].name}</Link></li>

                                            {
                                                this.props.services.map((item, idx) => {
                                                    if (item.parent == (this.props.services && this.props.services[0] && this.props.services[0]._id)) {
                                                        return (
                                                            <li><Link to={`/osiguranje/${item._id}/${item.name.toLowerCase().replace(/ /g, '-')}`} className={location === `/osiguranje/${item._id}/${item.name.toLowerCase().replace(/ /g, '-')}` ? "nav-link active" : "nav-link"}>{item.name}</Link></li>

                                                        )
                                                    }
                                                })
                                            }

                                        </ul>
                                    </div>






                                    <div style={{ overflow: 'hidden' }}>
                                        <ul className={this.state.submenu5 ? 'content2' : 'noContent2'}>
                                            <button onClick={() => this.setState({ submenu5: !this.state.submenu5, submenu: true })} className="arrowLeft"><Isvg src={arrowRight}></Isvg></button>
                                            <li><Link to={`/osiguranje/${this.props.services && this.props.services[1] && this.props.services[0]._id}/${this.props.services && this.props.services[1] && this.props.services[1].name && this.props.services[1].name.toLowerCase().replace(/ /g, '-')}`} className={location === `/osiguranje/${this.props.services && this.props.services[1] && this.props.services[1]._id}/${this.props.services && this.props.services[1] && this.props.services[1].name && this.props.services[1].name.toLowerCase().replace(/ /g, '-')}` ? "nav-link active" : "nav-link"}>{this.props.services && this.props.services[1] && this.props.services[1].name}</Link></li>

                                            {
                                                this.props.services.map((item, idx) => {
                                                    if (item.parent == (this.props.services && this.props.services[1] && this.props.services[1]._id)) {
                                                        return (
                                                            <li><Link to={`/osiguranje/${item._id}/${item.name.toLowerCase().replace(/ /g, '-')}`} className={location === `/osiguranje/${item._id}/${item.name.toLowerCase().replace(/ /g, '-')}` ? "nav-link active" : "nav-link"}>{item.name}</Link></li>

                                                        )
                                                    }
                                                })
                                            }

                                        </ul>
                                    </div>


                                    <div style={{ overflow: 'hidden' }}>
                                        <ul className={this.state.submenu6 ? 'content2' : 'noContent2'}>
                                            <button onClick={() => this.setState({ submenu6: !this.state.submenu6, submenu: true })} className="arrowLeft"><Isvg src={arrowRight}></Isvg></button>
                                            <li><Link to='/reports' className={location === "/reports" ? "nav-link active" : "nav-link"}>Izvještaji</Link></li>
                                            <li><Link to='/regulations' className={location === "/regulations" ? "nav-link active" : "nav-link"}>Propisi</Link></li>
                                            <li><Link to='/complaints' className={location === "/complaints" ? "nav-link active" : "nav-link"}>Pritužbe</Link></li>



                                        </ul>
                                    </div>




                                </div>
                            </div>
                        </>}
                </div>

                <div className="bgcnav2">
                    {this.props.about || this.props.requiredDoc ||
                        this.props.contact || this.props.gallery ||
                        this.props.news || this.props.newsDetail ||
                        this.props.reports || this.props.regulations ||
                        this.props.complaints || this.props.damage ||
                        this.props.reportDamage ?
                        <img src={nav} className="img-fluid" />
                        : null
                    }
                    {this.props.locations || this.props.detailLocation ?
                        <img src={poslovnce} className="img-fluid" />
                        : null
                    }


                    {
                        this.props[0].location.pathname.indexOf('osiguranje') !== -1 ?
                            this.props.services.map((item, idx) => {
                                if (this.props[0].match.params.id == item._id)
                                    return (
                                        <img src={item.image} className="img-fluid" />
                                    )
                            })
                            :

                            null
                    }
                </div>



                {this.props.about || this.props.property ||
                    this.props.accidents || this.props.reports ||
                    this.props.regulations || this.props.complaints ||
                    this.props.damage || this.props.reportDamage ||
                    this.props.requiredDoc || this.props.transport ||
                    this.props.odgovornost || this.props.healthy ||
                    this.props.authoutity || this.props.kasko
                    || this.props.insurancePassengers || this.props.accidentInsurance
                    || this.props.combinedInsurance || this.props.collectiveInsurance
                    || this.props.studentsInsurance || this.props.guestInsurance || this.props.sportsinsurance || this.props.pozar
                    || this.props.opasnost || this.props.provale || this.props.lom || this.props.domacinstva
                    ?
                    this.state.width >= 767 ?

                        <div className="sticky">

                            <Link to="/contact"><Button><Isvg src={contact} /></Button></Link>
                            <Link to="/AllLocations"><Button><Isvg src={location} /></Button></Link>
                        </div>
                        : null

                    : null}
            </>
        )
    }
}

export default navbar
