import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import Navigation from '../containers/navbar.js';
import Footer from '../containers/footer.js';
import Our from '../containers/home/ourOffices.js';
import Map from '../containers/map.js';
import AllLocationsComponent from '../containers/allLocationsComponent.js';
import DefaultLayout from '../containers/defaultLayout';




import {
    Container, Col, Row, Button
} from 'reactstrap';

export class detailLocation extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount(){
        window.scrollTo(0,0);
    }
    

    render() {

        let location;

        for(let i=0;i<this.props.offices.length;i++){
            if (this.props.cities && this.props.cities[this.props[0].match.params.id] && this.props.offices[i].city == this.props.cities[this.props[0].match.params.id]._id){
                location = this.props.offices[i].location;
                console.log(this.props.offices[i].location);
                break;
            }
        }




        const detailLocation = true;
        return (
            <div>
           
                <Container>
                    <div className="bredcrumb">
                        <h3>POSLOVNICE</h3>

                        <p> <Link to="/">Početna</Link> / <span>Poslovnice</span></p>
                    </div>
                </Container>
                <div className="allLocations detail">
                    <div className="wrapper">
                        <h6>POSLOVNICE</h6>
                    </div>
                    <Container>

                        <h4>{this.props.cities && this.props.cities[this.props[0].match.params.id] && this.props.cities[this.props[0].match.params.id].name && this.props.cities[this.props[0].match.params.id].name.toUpperCase()}</h4>
                        <div dangerouslySetInnerHTML={{__html: this.props.cities && this.props.cities[this.props[0].match.params.id] && this.props.cities[this.props[0].match.params.id].content}}></div>

                    </Container>
                    { location ?
                    <Map  {...this.props} coords={location} />
                    :
                    null
                    }



                    <Container>
                        <div className="infoWrapper">
                            <Row>
                                {
                                    this.props.offices.map((item, idx) => {
                                        if (this.props.cities && this.props.cities[this.props[0].match.params.id] &&  this.props.cities[this.props[0].match.params.id]._id == item.city)
                                        return (
                                            <Col xl="4" lg="4" md="4" xs="12">

                                            <div className="infoBox">
                                                <h3>{item.name}</h3>
        
                                                <li>Tel: {item.tel}</li>
                                                <li>Fax: {item.fax}</li>
                                                <li>E-mail: <br />
                                                    {item.email}</li>
                                                <li>Radno vrijeme: <br />
                                                    {item.workingTime}</li>
        
                                            </div>
                                        </Col>
        
                                        )
                                    })
                                }
                                

                                
                                

                            </Row>

                        </div>

                     



                        <h2>PRONAĐITE POSLOVNICU</h2>


                        <AllLocationsComponent {...this.props}/>

                    </Container>
                </div>
            </div>
        )
    }
}

export default DefaultLayout(detailLocation)
