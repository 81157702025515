import React, { Component } from 'react';
import Navigation from '../containers/navbar';
import Carousel from '../containers/home/carousel.js';
import Insurance from '../containers/home/insurance.js';
import Why from '../containers/home/why.js';
import Document from '../containers/home/document';
import OurOffices from '../containers/home/ourOffices.js';
import News from '../containers/news.js';
import Gallery from '../containers/home/gallery.js';
import Contact from '../containers/home/contact.js';
import Footer from '../containers/footer.js';
import DefaultLayout from '../containers/defaultLayout';



import news1 from '../images/news/news1.png';
import news2 from '../images/news/news2.png';
import news3 from '../images/news/news3.png';

const jsonNews = [
    {
        _id: 1,
        image: news1,
        title: "LOREM IPSUM DOLOR SIT AMET, CONSETETUR DIPSCING",
        description: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam.",
        date: "10",
        month: "jul",
        link: "/newsDetail"
    },
    {
        _id: 2,
        image: news2,
        title: "LOREM IPSUM DOLOR SIT AMET, CONSETETUR DIPSCING",
        description: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam.",
        date: "10",
        month: "jul",
        link: "/newsDetail"
    },
    {
        _id: 3,
        image: news3,
        title: "LOREM IPSUM DOLOR SIT AMET, CONSETETUR DIPSCING",
        description: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam.",
        date: "10",
        month: "jul",
        link: "/newsDetail"
    },
    ]


class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            news: []
        }
    }
    componentWillMount(){
        window.scroll(0,0);
    }


    componentDidMount(){
        this.props.core.fetchLastNews((data) => {
            this.setState({news: data});
        })
    }

    render() {
        const arrow = true;
        return (
            <div>
                <Carousel {...this.props}/>
                <Insurance services={this.props.services}/>
                <Why {...this.props}/>
                <Document/>
                <OurOffices {...this.props}/>

   
                <News items={this.state.news}/>
         
                <Gallery {...this.props}/>
                <Contact {...this.props}/>



            </div>
        )

    }

}

export default DefaultLayout(Home) 