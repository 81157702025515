import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import DefaultLayout from '../containers/defaultLayout';
import DamageMenu from '../containers/damageMenu.js'
import { Container, Row, Col } from 'reactstrap';
import Isvg from 'react-inlinesvg';

import downArrow from '../images/svg/down-arrow.svg';


export class damage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            downloads: []
        }
    }

    componentDidMount() {
        this.props.core.fetchDownloads({ category: 'stete' }, (data) => {

            this.setState({
                downloads: data,
            })
        })

    }

    componentWillMount(){
        window.scroll(0,0);
    }
    render() {
        return (
            <>
                <Container>
                    <div className="bredcrumb">
                        <h3>ŠTETE</h3>

                        <p> <Link to="/">Početna</Link> / Štete  / <span>Izvod iz pravilnika</span></p>
                    </div>
                </Container>

                <div className="damage">
                    <div className="wrapper">
                        <h6>ŠTETE</h6>
                    </div>


                    <Container>
                        <Row>
                            <DamageMenu />

                            <Col xl="9" lg="9" md="8" xs="12">
                                <div className="damageContent">
                                    <h2>IZVOD IZ PRAVILNIKA</h2>

                                    {
                                        this.state.downloads.map((item, idx) => {
                                            return (
                                                <div className="reportBox">
                                                     <a target="_blank" href={item.file} ><p>{item.name}</p></a>
                                                    <a target="_blank" href={item.file}><Isvg src={downArrow} /></a>
                                                </div>



                                            )
                                        })
                                    }



                                </div>

                            </Col>
                        </Row>
                    </Container>
                </div>
            </>
        )
    }
}

export default DefaultLayout(damage)
