import React, { Component } from 'react';
import Isvg from 'react-inlinesvg';
import { Link } from 'react-router-dom';
import logo from '../images/svg/logo.svg';
import insta from '../images/footer/facebook.svg';
import face from '../images/footer/instagram.svg';

import {
    Container, Row, Col, Button, NavItem, NavLink
} from 'reactstrap';


class Footer extends Component {
    render() {
        return (

            <>
                {this.props.arrow ?
                    <div className="d-flex justify-content-end scrollToTop">
                        <Button></Button>
                    </div>
                    : null}
                <div className="footer">
                    <Container fluid>
                        <Row>
                            <Col xl="1" xs="12" lg="12" md="12">
                                <div className="logo">
                                    <Isvg src={logo} />
                                </div>

                                <div className="icon">
                                    <Link to="#" className="face">  <Isvg src={face} />  </Link>
                                    <Link to="#">  <Isvg src={insta} />  </Link>
                                </div>
                            </Col>
                            <Col xl="10" xs="12" lg="12" md="12" className="offset-xl-1">
                                <div className="footerMenu">
                                    <NavItem>
                                        <Link className="nav-link" to="/">POČETNA</Link>
                                    </NavItem>
                                    <NavItem>
                                        <Link className="nav-link" to="/about">O NAMA</Link>
                                    </NavItem>
                                    <NavItem>
                                        <Link className="nav-link" to="/AllLocations">POSLOVNICE</Link>
                                    </NavItem>
                                    <NavItem>
                                        <Link className="nav-link" to="/osiguranje/5d42eaa2eee16f6044d5ff32/osiguranje-imovine">OSIGURANJA</Link>
                                    </NavItem>
                                    <NavItem>
                                        <Link className="nav-link" to="/damage">ŠTETE</Link>
                                    </NavItem>
                                    <NavItem>
                                        <Link className="nav-link" to="/news">NOVOSTI</Link>
                                    </NavItem>
                                    <NavItem>
                                        <Link className="nav-link" to="/gallery">GALERIJA</Link>
                                    </NavItem>
                                    <NavItem>
                                        <Link className="nav-link" to="/reports">PREUZIMANJA</Link>
                                    </NavItem>
                                    <NavItem>
                                        <Link className="nav-link" to="/contact">KONTAKT</Link>
                                    </NavItem>
                                </div>

                                <div className="info">
                                    <div className="content">
                                        <p>Direkcija Nešković osiguranja</p>
                                        <h6>Ulica Sremska 3, 76300 Bijeljina</h6>
                                    </div>
                                    <div className="content">
                                        <p>Telefon</p>
                                        <h6>00 387 55 294 310</h6>
                                    </div>

                                    <div className="content">
                                        <p>Fax</p>
                                        <h6>00 387 55 294 317</h6>
                                    </div>
                                    <div className="content">
                                        <p>E-mail</p>
                                        <h6>info@neskovicosiguranje.com</h6>
                                    </div>
                                </div>
                            </Col>

                            <Col xl="12" xs="12" lg="12" md="12">
                                <div className="copyRight">
                                    <p>Copyright © Nešković osiguranje a.d. Bijeljina.</p>
                                    <p>Created by <span><a href="https://novamedia.agency" target="_blank">NOVA MEDIA</a></span></p>
                                </div>
                            </Col>
                        </Row>


                    </Container>
                </div>
            </>
        )
    }
}

export default Footer;
