











import React, { Component } from 'react'
import { Link } from 'react-router-dom';


import Navigation from '../../containers/navbar.js';
import Footer from '../../containers/footer.js';

import PropertyMenu from '../../containers/propertyMenu.js';

import DefaultLayout from '../../containers/defaultLayout.js';


import {
    Container, Row, Col
} from 'reactstrap';

export class property extends Component {
    constructor(props) {
        super(props);

        this.state = {
            menu: {}
        }
    }

    componentWillMount(){
        window.scroll(0,0);
    }
    render() {

        return (

            <div>
                <Container>
                    <div className="bredcrumb">
                        <h3>IMOVINA</h3>
                        <p> <Link to="/">Početna</Link> / Osiguranja / Imovina  / <span>Osiguranje od požara i nekih drugih opasnosti</span></p>
                    </div>
                </Container>


                <div className="property">
                    <div className="wrapper">
                        <h6>OSIGURANJA</h6>
                    </div>
                    <Container>
                        <Row>
                            <PropertyMenu />
                            <Col xl="9" lg="9" md="8" xs="12">
                                <div className="domacinstvo">
                                    <h2>OSIGURANJE OD POŽARA I NEKIH DRUGIH OPASNOSTI</h2>


                                    <p>Predmet ove vrste imovinskog osiguranja mogu biti različiti građevinski objekti (stambeni
                                    objekti, objekti industrijskih i zanatskih organizacija, objekti poljoprivrednih gazdinstva…) i
                                    druge nepokretnosti, zatim namještaj, mašine, aparati, uređaji i ostala oprema, alati, zalihe
                                    materijala, zalihe nedovršenih proizvoda, zalihe gotovih proizvoda, zalihe robe, itd.</p>

                                    <p><span> Osnovni rizici pokriveni ovim osiguranjem su:</span></p>

                                    <li>- požar,</li>
                                    <li> - eksplozija,</li>
                                    <li>  - oluja,</li>
                                    <li> - grad,</li>
                                    <li> - udar sopstvenog motornog vozila, sopstvene pokretne radne mašine u osigurani građevinski
                                    objekat, </li>
                                    <li> - pad letjelice,</li>
                                    <li> - manifestacija i demonstracija.</li>


                                    <p><span> Pored osnovnih rizika uz minimalnu doplatu premije osiguravajuća zaštita se
                                    može proširiti i na jedan ili više dopunskih rizika:</span></p>

                                    <li> - poplavu, bujicu i visoku vodu,</li>
                                    <li> - izlivanje vode iz instalacija,</li>
                                    <li>  - iscurenje,</li>
                                    <li> - samozapaljenje zaliha,</li>
                                    <li> - udar nepoznatog vozila u osigurani građevinski objekat…</li>

                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>



            </div>
        )
    }
}

export default DefaultLayout(property)


















