import React, { Component } from 'react'
import { Link } from 'react-router-dom';


import Navigation from '../../containers/navbar.js';
import Footer from '../../containers/footer.js';

import PropertyMenu from '../../containers/propertyMenu.js';
import DefaultLayout from '../../containers/defaultLayout.js';

import {
    Container, Row, Col
} from 'reactstrap';

export class property extends Component {
    constructor(props) {
        super(props);

        this.state = {
            menu: {}
        }
    }

    componentWillMount(){
        window.scroll(0,0);
    }
    render() {



        return (
            <>
                <div>

                    <Container>
                        <div className="bredcrumb">
                            <h3>IMOVINA</h3>
                            <p> <Link to="/">Početna</Link> / Osiguranja / <span>Imovina</span></p>
                        </div>
                    </Container>

                    <div className="property">
                        <div className="wrapper">
                            <h6>OSIGURANJA</h6>
                        </div>
                        <Container>
                            <Row>
                                <PropertyMenu />
                                <Col xl="9" lg="9" md="8" xs="12">
                                    <div className="domacinstvo">
                                        <h2>IMOVINA</h2>
                                        <p>Vaša imovina svakodnevno je izložena brojnim rizicima kao što su požar, oluja, poplava, udar
                                    groma, grad, provalna krađa, različite havarije…</p>
                                        <p>Svjesni ste da put do sticanja imovine nije bio lak. Investirali ste novac, znanje, vrijeme i zašto
                                        onda da rizikujete da navedeni ali i drugi iznenadni, nepredvidivi i od Vaše volje nezavisni
                                        događaji u samo jednom trenutku sve unište?
                                        Kupite polisu NEŠKOVIĆ OSIGURANJA i time brigu o saniranju eventualnih šteta na Vašoj
                                        imovini prepustite nama.
                                        U ponudi je veliki broj varijanti pokrića od osnovnih i dopunskih rizika sve zavisno od Vaših želja i
                                     mogućnosti.</p>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>




                </div>
            </>
        )
    }
}

export default DefaultLayout(property)
