import React, { Component } from 'react'

export class istorijat extends Component {
    render() {
        return (
            <div className="content">
            <h4>ISTORIJAT</h4>
            <p>Organizacija za osiguranje "NEŠKOVIĆ" DD, sa sjedištem u Bijeljini, osnovana je u februaru 1997. 
            godine.</p>

            <p>U martu 2002. godine izvršena je promjene naziva i od tada ista posluje pod nazivom 
            Akcionarsko Društvo za osiguranje "NEŠKOVIĆ OSIGURANJE" p.o., sa sjedištem u Bijeljini, 
            Sremska br. 3.</p>

            <p>Od momenta osnivanja pa sve do danas u kreiranju poslovne politike Društva polazi se od:</p>

            <div className="text history">
            <li>-obezbjeđenja visokog kvaliteta usluga osiguranja,</li>
            <li>-sigurne zaštite osiguranika,</li>
            <li>-sigurnosti, solventnosti i stabilnosti poslovanja,
            profesionalnosti i stručnosti u radu,</li>
            <li>-primjene novih trendova i dostignuća u osiguranju,</li>
            <li>-principa stalnog ulaganja u razvoj djelatnosti osiguranja,</li>
            <li>-obezbjeđenja visokih pozicija na tržištu osiguranja,</li>
            <li>-primjene načela zakonitosti i pravila struke osiguranja…</li>
            </div>

            <p>Imajući u vidu da organizacija predstavlja veoma značajan aspekt rasta i razvoja preduzeća 
            NEŠKOVIĆ OSIGURANJE AD je od samog početka poslovanja mnogo pažnje davalo organizaciji, 
            odnosno organizacionoj strukturi društva, njenom funkcionisanju i neprestanom poboljšanju i 
            prilagođavanju izmijenjenim tržišnim okolnostima.</p>

            <p> Društvo je osnovano kao akcionarsko društvo, dakle sa načinom upravljanja koji je 
            karakterističan akcionarskim društvima i koji obuhvata sva pitanja povezana sa interaktivnim 
            ponašanjem top menadžmenta, vlasnika (akcionara), upravnog odbora i ostalih stejkholdera.</p>

            <p>Centralno mjesto u tim interakcijama pripada upravnom odboru, uslijed njegove izvorno 
            definisane odgovornosti upravljanja u interesu vlasnika.</p>

            <p>Zahvaljujući takvoj poslovnoj politici već u prvoj deceniji poslovanja "NEŠKOVIĆ OSIGURANJE" 
            AD izraslo je u modernu kompaniju sa preko 30 poslovnih jedinica, preko 50 zaposlenih i preko 
            100 angažovanih posrednika u poslovima osiguranja.</p>

            <p>Poslovne jedinice i prodajna mjesta NEŠKOVIĆ OSIGURANJA locirani su širom RS i zahvaljujući 
            tome obezbjeđena je snažna poslovna mreža koja se iz godine u godinu usavršava, i to kako 
            raznovrsnošću i kvalitetom ponuđenih usluga, tako i razvojem informacionog sistema.</p>
        </div>
        )
    }
}

export default istorijat
