import React, { Component } from 'react'
import DefaultLayout from '../../containers/defaultLayout';
import { Link } from 'react-router-dom';

import AccidentsMenu from '../../containers/accidentsMenu.js';

import { Container, Row, Col } from 'reactstrap';


export class sportsinsurance extends Component {


    componentWillMount(){
        window.scroll(0,0);
    }
    render() {
        return (
            <>
            <Container>
                <div className="bredcrumb">
                    <h3>NEZGODE</h3>

                    <p> <Link to="/">Početna</Link> / Osiguranja / <span> Osiguranje članova sportskih organizacija</span></p>
                </div>
            </Container>
            <div className="accidents">
                <div className="wrapper">
                    <h6>OSIGURANJA</h6>
                </div>




                <Container>
                    <Row>
                        <AccidentsMenu />

                        <Col xl="9" lg="9" md="8" xs="12">
                            <div className="content">
                                <h2>OSIGURANJE ČLANOVA SPORTSKIH ORGANIZACIJA</h2>

                              <p> Ugovarač ove vrste osiguranja može biti svaka sportska organizacija koja ima svojstvo pravnog 
                                lica, kao i fizička lica koja imaju interes da zaključe ovo osiguranje.
                                Pravo na naknadu štete ostvaruju osigurani članovi sportskih organizacija i to u slučaju kada je 
                                nezgoda nastala pri sportskoj djelatnosti, za vrijeme dolaska na trening i takmičenje i pri 
                                povratku kući, kao i pri obavljanju dužnosti po nalogu uprave društva.</p>

                   
                            </div>


                        </Col>
                    </Row>
                </Container>



            </div>

        </>
        )
    }
}

export default DefaultLayout(sportsinsurance)
