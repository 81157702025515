import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import DefaultLayout from '../../containers/defaultLayout';
import DamageMenu from '../../containers/damageMenu.js'
import { Container, Row, Col } from 'reactstrap';
import Isvg from 'react-inlinesvg';

import drop_down from '../../images/svg/drop_down.svg';

export class requiredDoc extends Component {
    constructor(props) {
        super(props);

        this.state = {
            opened: null,
            downloads: []
        }
    }

    componentDidMount() {
        this.props.core.fetchDownloads({ category: 'stete-dokumentacija' }, (data) => {

            this.setState({
                downloads: data,
            })
        })

    }

    componentWillMount(){
        window.scroll(0,0);
    }
    render() {
        return (
            <>
                <Container>
                    <div className="bredcrumb">
                        <h3>ŠTETE</h3>

                        <p> <Link to="/">Početna</Link> / Štete  / <span>Izvod iz pravilnika</span></p>
                    </div>
                </Container>

                <div className="damage requiredDoc">
                    <div className="wrapper">
                        <h6>ŠTETE</h6>
                    </div>


                    <Container>
                        <Row>
                            <DamageMenu />

                            <Col xl="9" lg="9" md="8" xs="12">
                                <div className="damageContent">
                                    <h2>MIN. POTREBNA DOKUMENTACIJA<br /> PRILIKOM PRIJAVE ŠTETE</h2>
                                    {
                                        this.state.downloads.map((item, idx) => {
                                            return (
                                                <div
                                                    onClick={() => {
                                                        this.setState({ opened: item._id });
                                                    }}
                                                    className={this.state.opened == item._id ? "reportBox open" : "reportBox"}   >
                                                    <p><span>{item.name}</span></p>
                                                    <Link to="#"><Isvg src={drop_down} /></Link>

                                                    {this.state.opened == item._id ?
                                                        <div>
                                                            <div dangerouslySetInnerHTML={{ __html: item.content }}>

                                                            </div>

                                                     <a href={item.file} target="_blank"><h6>PREUZMITE OBRAZAC</h6></a>

                                                        </div>


                                                        : null}


                                                </div>
                                            )
                                        })
                                    }



                                </div>

                            </Col>
                        </Row>
                    </Container>
                </div>
            </>
        )
    }
}

export default DefaultLayout(requiredDoc)
