import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import Isvg from 'react-inlinesvg';

import AnimtedButton from '../../components/animatedButton.js';
import {
    Container, Row, Col, Button
} from 'reactstrap';

export class insurance extends Component {
    constructor(props) {
        super(props);

        this.state = {
            menu: 'item1',
            selected: 0
        }
    }
    render() {
        const buttonText = "DETALJNIJE";
        return (
            <div>


                <div className="wrapper">
                    <h6>NAŠE USLUGE</h6>
                </div>

                <Container>
                    <div className="wrappInsuranceButton">

                        {
                            this.props.services.map((item, idx) => {
                                if (!item.parent)
                                    return (
                                        <div className={this.state.selected == idx ? "active d-block" : "d-block"}>
                                            <Button onClick={() => {
                                                this.setState({ selected: idx });
                                            }}>
                                                <Isvg src={item.icon.replace('http:', 'https:')} />
                                            </Button>
                                            <p>OSIGURANJE</p>
                                            <h6>{item.homeName}</h6>
                                        </div>

                                    )
                            })
                        }
                    </div>


                </Container>
                <div className="wrapInsuranceContent">
                    <div className="eee">
                        <div className="imageWrapper">
                            <img src={this.props.services && this.props.services[this.state.selected] && this.props.services[this.state.selected].homeImage} className="img-fluid" />
                        </div>
                    </div>
                    <Container>
                        <Row>
                            <Col xl="6" md="6" xs="12" className="offset-xl-6  offset-lg-6 offset-md-6">
                                <div className="property">

                                    <h6>OSIGURANJE</h6>
                                    <h1>{this.props.services && this.props.services[this.state.selected] && this.props.services[this.state.selected].homeName}</h1>

                                    <div dangerouslySetInnerHTML={{ __html: this.props.services && this.props.services[this.state.selected] && this.props.services[this.state.selected].homeContent }}>
                                    </div>
                                    <Link to={`/osiguranje/${this.props.services && this.props.services[this.state.selected] && this.props.services[this.state.selected]._id}/${this.props.services && this.props.services[this.state.selected] && this.props.services[this.state.selected] && this.props.services && this.props.services[this.state.selected] && this.props.services[this.state.selected].name &&  this.props.services && this.props.services[this.state.selected] && this.props.services[this.state.selected].name.toLowerCase().replace(/ /g, '-')}`}>
                                        <div className="button">
                                            <AnimtedButton buttonText={buttonText} />
                                        </div>
                                    </Link>

                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>

            </div>
        )
    }
}

export default insurance
