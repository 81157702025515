import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form'

import {
    Container, Row, Col, Button, FormGroup,
    Input, NavItem
} from 'reactstrap';
import AnimtedButton from '../../components/animatedButton.js';
import { renderField } from './fields'

const required = value => value ? undefined : "Required"



const form = (props) => {
    const { handleSubmit, pristine, reset, submitting } = props;
    const buttonText = "POŠALJI";

    return (
        <form onSubmit={handleSubmit}>
            <FormGroup>
                <Field validate={[required]} component={renderField} type="text" placeholder="Ime" name="firstName" />
            </FormGroup>
            <FormGroup>
                <Field validate={[required]} component={renderField} type="text" placeholder="Prezime" name="lastName" />
            </FormGroup>
            <FormGroup>
                <Field validate={[required]} component={renderField} type="text" placeholder="Telefon" name="phone" />
            </FormGroup>
            <FormGroup>
                <Field validate={[required]} component={renderField} type="text" placeholder="E-mail" name="email" />
            </FormGroup>
            <FormGroup>
                <Field validate={[required]} component={renderField} type="text" placeholder="Tema" name="subject" />
            </FormGroup>
            <FormGroup>
                <Field validate={[required]} component={renderField} type="textarea" placeholder="Poruka" name="message" />
            </FormGroup>

            <AnimtedButton buttonText={buttonText} />

        </form>
    )
}
export default reduxForm({
    form: 'form'  // a unique identifier for this form
})(form)
