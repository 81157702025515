import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import DefaultLayout from '../../containers/defaultLayout.js';

import Navigation from '../../containers/navbar.js';
import Footer from '../../containers/footer.js';

import PropertyMenu from '../../containers/propertyMenu.js';


import {
    Container, Row, Col
} from 'reactstrap';

export class stvariDomacinstvo extends Component {
    constructor(props) {
        super(props);

        this.state = {
            menu: {}
        }
    }

    componentWillMount(){
        window.scroll(0,0);
    }
    render() {

        return (
            <div>
                <Container>
                    <div className="bredcrumb">
                        <h3>IMOVINA</h3>
                        <p> <Link to="/">Početna</Link> / Osiguranja / Imovina  / <span> Osiguranje stvari domaćinstva</span></p>
                    </div>
                </Container>


                <div className="property">
                    <div className="wrapper">
                        <h6>OSIGURANJA</h6>
                    </div>
                    <Container>
                        <Row>
                            <PropertyMenu />
                            <Col xl="9" lg="9" md="8" xs="12">
                                <div className="domacinstvo">
                                    <h2>OSIGURANJE STVARI DOMAĆINSTVA</h2>

                                    <p><span>Vaše stvari u domaćinstvu izložene su brojnim rizicima, kao što su:</span> </p>

                                    <li>- požar, </li>
                                    <li>- udar groma, </li>
                                    <li>- eksplozija, </li>
                                    <li>- oluja, </li>
                                    <li>- grad, </li>
                                    <li>- poplava i bujica, </li>
                                    <li>- izlivanje vode, </li>
                                    <li>- lom stakla, </li>
                                    <li>- provalna, obična i razbojnička krađa, </li>
                                    <li>- pad letjelice, </li>
                                    <li>- manifestacija i demonstracija, </li>
                                    <li>- razbojništvo. </li>


                                    <p><span>Najbrži način da sanirate posljedice u slučaju da se ostvari neki od navedenih rizika jeste da
                                    osigurate stvari u Vašem domaćinstvu. Osigurati se mogu stvari u stalno nastanjenom stanu
                                    (stan koji nije više od 60 dana bez prekida nenastanjen u toku 12 mjeseci) na području
                                    Republike Srpske i Federacije BiH i to:</span></p>

                                    <li>- predmeti koji služe za opremu i uređenje stana, </li>
                                    <li>- stvari za ličnu upotrebu i potrošnju, </li>
                                    <li>- gotov novac, vrijednosni papiri, drago kamenje, plemeniti metali, pravi biseri i predmeti od
                                    plemenitih metala i pravih bisera, umjetnički predmeti, zbirke poštanskih markica, starog novca,
                                    znački i sl., </li>
                                    <li>- lovačko i trofejno – staro oružje, </li>
                                    <li> - lična dokumenta i dokumenta u vezi sa osiguranim stvarima. </li>



                                    <p><span> Ako se posebno i dodatno ugovori predmet osiguranja mogu biti i:</span></p>

                                    <li>- boje na zidu i građevinskoj stolariji i razne obloge na podu, zidu i tavanici stana, kao što su
                                    tapete, lamperija, pločice, parket, tapisoni, i sl.;</li>
                                    <li>- stakla na vratima i prozorima stana.</li>

                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>



            </div>
        )
    }
}

export default DefaultLayout(stvariDomacinstvo)
