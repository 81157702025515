import React, { Component } from 'react'
import DefaultLayout from '../containers/defaultLayout';
import { Link } from 'react-router-dom';

import { Container } from 'reactstrap';
export class authoutity extends Component {

    componentWillMount(){
        window.scroll(0,0);
    }
    render() {
        return (
            <>
                <Container>
                    <div className="bredcrumb">
                        <h3>AUTOODGOVORNOST</h3>

                        <p> <Link to="/">Početna</Link> / Osiguranja / <span>Autoodgovornost</span></p>
                    </div>
                </Container>

                <div className="authoutity">
                    <div className="wrapper">
                        <h6>OSIGURANJA</h6>
                    </div>
                    <Container>
                        <h2>AUTOODGOVORNOST</h2>

                        <p>Da bi se zaštitile žrtve saobraćajnih nezgoda, kao i njihova imovina, vlasnici motornih vozila moraju zaključiti ugovor o osiguranju
                        od automobilske odgovornosti.</p>

                        <p>Zaključivanje ugovora o osiguranju od automobilske odgovornosti je zakonom propisana obaveza koja ima za cilj da osigura
                         naknade šteta nastalih kao posljedica saobraćajnih nezgoda.</p>

                        <p>Kupovinom polise osiguranja od autoodgovornosti Vi osiguravate Vašu odgovornost prema žrtvama saobraćajne nezgode koja je
                         izazvana Vašom nepažnjom i krivicom. Nastalu štetu umjesto Vas će platiti osiguravajuće društvo čiju ste polisu kupili.</p>

                        <p>Izbor kvalitetne osiguravajuće kuće je Vaša odgovornost jer samo tako možete biti sigurni da će žrtve Vaše nepažnje dobiti
                        pravičnu nadoknadu za štetu koju ste Vi prouzrokovali.</p>

                        <p>zbor NEŠKOVIĆ OSIGURANJA garantuje takav kvalitet iz razloga što smo jedna od rijetkih osiguravajućih kuća koja nema
                            likvidiranih a neisplaćenih šteta.</p>

                        <p>Uslovi za osiguranje AO</p>
                    </Container>

                </div>
            </>
        )
    }
}

export default DefaultLayout(authoutity)
