import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { NavLink as RRNavLink } from 'react-router-dom';


import DefaultLayout from '../containers/defaultLayout.js';
import Isvg from 'react-inlinesvg';

import LeftSideMenu from '../components/LeftMenu.js';
import drop_down from '../images/svg/drop_down.svg';


import {
    Container, Row, Col, NavItem, NavLink
} from 'reactstrap';
import { throws } from 'assert';

export class service extends Component {
    constructor(props) {
        super(props);

        this.state = {
            menu: {},
            open: true
        }
    }

    componentDidMount() {
        this.props.core.fetchServiceItem(this.props[0].match.params.id, (data) => {
            window.scrollTo(0, 0);
            this.setState(data);
        })
    }

    componentDidUpdate(prevProps) {
        if (prevProps[0].location.pathname !== this.props[0].location.pathname) {
            this.setState({
                parent: null,
                documentName: null,
                file: null
            }, () => {
                this.props.core.fetchServiceItem(this.props[0].match.params.id, (data) => {
                    window.scrollTo(0, 0);
                    this.setState(data);
                })

            })

        }
    }

    componentWillMount() {
        window.scroll(0, 0);
    }

    render() {

        let services = this.props.services;

        let name;
        let childs;
        if (this.state.parent) {
            for (let i = 0; i < services.length; i++) {
                if (services[i]._id == this.state.parent) {
                    name = services[i].name;
                    childs = true;
                    break;
                }
            }
        } else {
            for (let i = 0; i < services.length; i++) {
                if (this.state._id == services[i].parent) {
                    childs = true;
                    break;
                }
            }
        }

        return (

            <div>
                <Container>
                    <div className="bredcrumb">
                        <h3>{this.state.parent ? name : this.state.name}</h3>
                        {this.state.parent ?
                            <p> <Link to="/">Početna</Link> / Osiguranja / {name}  / <span>{this.state.name}</span></p>
                            :
                            <p> <Link to="/">Početna</Link> / Osiguranja / <span>{this.state.name}</span></p>

                        }
                    </div>
                </Container>


                <div className="property">
                    <div className="wrapper">
                        <h6>OSIGURANJA</h6>
                    </div>
                    <Container>
                        <Row>
                            {childs ?
                                <Col xl="3" lg="3" md="4" xs="12">
                                    <div className="propertyBox">
                                        <div className="contentBox">
                                            <div className="wrappButton">
                                                <Link to={`/osiguranje/${this.state.parent ? this.state.parent : this.state._id}/${this.state.parent ? (name && name.toLowerCase().replace(/ /g, '-')) : (this.state.name && this.state.name.toLowerCase().replace(/ /g, '-'))}`}> <h2>{this.state.parent ? name : this.state.name}</h2></Link>
                                                <button className="d-md-none" onClick={() => this.setState({ open: !this.state.open })}> <Isvg src={drop_down} /></button>
                                            </div>

                                            {this.state.open ?
                                                this.props.services.map((item, idx) => {
                                                    if ((this.state.parent && item.parent == this.state.parent) || (!this.state.parent && item.parent == this.state._id))
                                                        return (
                                                            <div key={idx}>
                                                                <NavItem>
                                                                    <NavLink to={`/osiguranje/${item._id}/${item.name.toLowerCase().replace(/ /g, '-')}`} exact tag={RRNavLink} activeClassName="activ">{item.name && item.name.toLowerCase()}</NavLink>
                                                                </NavItem>
                                                            </div>

                                                        )
                                                })
                                                : null}
                                        </div>
                                    </div>
                                </Col>
                                :
                                null
                            }
                            {childs ?
                                <Col xl="9" lg="9" md="8" xs="12">
                                    <div className="domacinstvo">
                                        <h2>{this.state.name}</h2>
                                        <div dangerouslySetInnerHTML={{ __html: this.state.content }}>
                                        </div>
                                        {this.state.file ?
                                            <a target="_blank" href={this.state.file}>{this.state.documentName}</a>
                                            : null
                                        }

                                    </div>
                                </Col>

                                :
                                <Col xl="12" lg="12" md="12" xs="12">
                                    <div className="domacinstvo">
                                        <h2>{this.state.name}</h2>
                                        <div dangerouslySetInnerHTML={{ __html: this.state.content }}>
                                        </div>
                                        {this.state.file ?
                                            <a target="_blank" href={this.state.file}>{this.state.documentName}</a>
                                            : null
                                        }

                                    </div>
                                </Col>

                            }
                        </Row>
                    </Container>
                </div>



            </div>
        )
    }
}

export default DefaultLayout(service)


















