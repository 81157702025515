import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import Navigation from '../containers/navbar.js';
import AllLocationsComponent from '../containers/allLocationsComponent.js';
import Footer from '../containers/footer.js';
import DefaultLayout from '../containers/defaultLayout';


import {
    Container, Col, Row, Button
} from 'reactstrap';

export class allLocations extends Component {

    componentWillMount() {
        window.scroll(0, 0);
    }
    render() {



        return (
            <div>



                <Container>
                    <div className="bredcrumb">
                        <h3>POSLOVNICE</h3>

                        <p> <Link to="/">Početna</Link> / <span>Poslovnice</span></p>
                    </div>
                </Container>
                <div className="allLocations">
                    <div className="wrapper">
                        <h6>POSLOVNICE</h6>
                    </div>
                    <Container>

                        <h4>SPISAK NAŠIH POSLOVNICA</h4>
                        <p>Posjetite
    prodajno mjesto
    NEŠKOVIĆ
    OSIGURANJA
    u Vašem gradu
    i izaberite pouzdanog
    partnera za bezbjedan
i siguran život!</p>

                        <h2>PRONAĐITE POSLOVNICU</h2>


                        <AllLocationsComponent {...this.props} />




                    </Container>
                </div>

            </div>
        )
    }
}

export default DefaultLayout(allLocations)
