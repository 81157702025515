import React, { Component } from 'react'
import DefaultLayout from '../../containers/defaultLayout';
import { Link } from 'react-router-dom';

import AccidentsMenu from '../../containers/accidentsMenu.js';

import { Container, Row, Col } from 'reactstrap';

export class guestInsurance extends Component {


    componentWillMount(){
        window.scroll(0,0);
    }
    render() {
        return (
            <>
                <Container>
                    <div className="bredcrumb">
                        <h3>NEZGODE</h3>

                        <p> <Link to="/">Početna</Link> / Osiguranja / <span> Osiguranje gostiju hotela, motela, kampova, banjskih liječilišta i slično</span></p>
                    </div>
                </Container>
                <div className="accidents">
                    <div className="wrapper">
                        <h6>OSIGURANJA</h6>
                    </div>




                    <Container>
                        <Row>
                            <AccidentsMenu />

                            <Col xl="9" lg="9" md="8" xs="12">
                                <div className="content">
                                    <h2>OSIGURANJE GOSTIJU HOTELA,
                                    MOTELA, KAMPOVA, BANJSKIH
                                LIJEČILIŠTA I SLIČNO</h2>

                                  <p>  Pravna i fizička lica koja se bave turističkim uslugama, kao što su, na primjer, hoteli, moteli,
                                    kampovi, banjska liječilišta i slično, mogu ugovoriti osiguranje gostiju od posljedica nesrećnog
                                    slučaja.</p>
                                    <p>   Ugovor o osiguranju se zaključuje prema knjizi gostiju ili drugoj evidenciji koja je propisana i
                                    počinje da važi, za svakog pojedinačnog gosta, od momenta kada je isti uveden u propisanu
                                    evidenciju a traje do momenta kada je prema hotelskom ili nekom drugom redu to svojstvo
                                    izgubio.</p>
                            </div>


                            </Col>
                        </Row>
                    </Container>



                </div>

            </>
        )
    }
}

export default DefaultLayout(guestInsurance)
