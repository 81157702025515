import React, { Component } from 'react'
import Isvg from 'react-inlinesvg';
import AnimtedButton from '../../components/animatedButton.js';



import drop_down from '../../images/svg/drop_down.svg';

import ContactForm from '../forms/homeContactForm';
import {
    Container, Row, Col, Button, FormGroup, Input, Label
} from 'reactstrap';


export class contact extends Component {
    constructor(props){
        super(props);

        this.state = {
            
        }
    }
    render() {
        const buttonText = "POŠALJI";
        return (
            <div className="contact">
                <div className="wrapper">
                    <h6>KONTAKT</h6>
                </div>
                <Container>
                    <h1>KONTAKTIRAJTE NAS</h1>
                    <p>Budite slobodni da nas kontaktirate. Odgovorićemo Vam u što kraćem roku.</p>

                    <ContactForm onSubmit={(dat) => {
                        this.props.core.contact(dat, () => {
                            this.setState({
                                _done: true
                            })
                        });
                    }}>

                    </ContactForm>
                    {this._done ?

                        <div className="alert alert-success messages" role="alert">
                            <p>Vaša poruka je uspješno poslata, očekujte odgovor ubrzo.</p>
                        </div>

                        :
                         null
                    }
                </Container>
            </div>
        )
    }
}

export default contact
