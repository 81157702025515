import React, { Component } from 'react'
import DefaultLayout from '../../containers/defaultLayout';
import { Link } from 'react-router-dom';

import AccidentsMenu from '../../containers/accidentsMenu.js';

import { Container, Row, Col } from 'reactstrap';

export class collectiveInsurance extends Component {

    componentWillMount(){
        window.scroll(0,0);
    }
    render() {
        return (
            <>
            <Container>
                <div className="bredcrumb">
                    <h3>NEZGODE</h3>

                    <p> <Link to="/">Početna</Link> / Osiguranja / <span> Kolektivno osiguranje zaposlenih radnika</span></p>
                </div>
            </Container>
            <div className="accidents">
                <div className="wrapper">
                    <h6>OSIGURANJA</h6>
                </div>




                <Container>
                    <Row>
                        <AccidentsMenu />

                        <Col xl="9" lg="9" md="8" xs="12">
                            <div className="content">
                                <h2>KOLEKTIVNO OSIGURANJE ZAPOSLENIH RADNIKA</h2>

                              <p> Kolektivno osiguranje zaposlenih radnika pruža zaštitu od sljedećih rizika: smrti usljed nezgode, 
                                trajnog invaliditeta i troškove liječenja s tim da su ovi rizici pokriveni za vrijeme dok radnik 
                                obavlja svoje redovno zanimanje.</p>

                   
                            </div>


                        </Col>
                    </Row>
                </Container>



            </div>

        </>
        )
    }
}

export default DefaultLayout(collectiveInsurance)
