import React, { Component } from 'react';

import {
    Input,
} from 'reactstrap';


class SelectComponent extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Input className={this.props.error ? 'required' : null} type={this.props.type} onChange={this.props.onChange}>
                {this.props.children}
            </Input>

        )
    }
}

class FormComponent extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Input className={this.props.error ? 'required' : null} type={this.props.type} onChange={this.props.onChange} placeholder={this.props.placeholder}>
                {this.props.children}
            </Input>

        )
    }
}


export const renderField = ({
    input,
    placeholder,
    type,
    children,
    meta: { touched, error },
}) => (

        <FormComponent
            placeholder={placeholder}
            type={type}
            errorText={touched && error}
            error={touched && error}
            children={children}
            {...input}
        />
    )

export const renderSelectField = ({
    input,
    placeholder,
    type,
    children,
    meta: { touched, error },
}) => (

        <SelectComponent
            placeholder={placeholder}
            type={type}
            errorText={touched && error}
            error={touched && error}
            children={children}
            {...input}
        />
    )
