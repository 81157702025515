import React, { Component } from 'react'
import DefaultLayout from '../../containers/defaultLayout';
import { Link } from 'react-router-dom';

import AccidentsMenu from '../../containers/accidentsMenu.js';

import { Container, Row, Col } from 'reactstrap';

export class combinedInsurance extends Component {

    componentWillMount(){
        window.scroll(0,0);
    }
    render() {
        return (
            <>
                <Container>
                    <div className="bredcrumb">
                        <h3>NEZGODE</h3>

                        <p> <Link to="/">Početna</Link> / Osiguranja / <span>Kolektivno kombinovano osiguranje zaposlenih radnika</span></p>
                    </div>
                </Container>
                <div className="accidents">
                    <div className="wrapper">
                        <h6>OSIGURANJA</h6>
                    </div>




                    <Container>
                        <Row>
                            <AccidentsMenu />

                            <Col xl="9" lg="9" md="8" xs="12">
                                <div className="content">
                                    <h2>OSIGURANJE AUTONEZGODE</h2>
                                    <p><span> Kolektivno kombinovano osiguranje zaposlenih radnika je zakonska obaveza poslodavaca koja
                                    može uključivati sljedeće rizike:</span></p>

                                    <li>- prirodna smrt,</li>
                                    <li> - smrt uslijed nezgode,</li>
                                    <li>  - trajni invaliditet i</li>
                                    <li> - troškovi liječenja.</li>



                                    <p>Od pomenutih rizika zaposleni su zaštićeni tokom 24 časa, odnosno i za vrijeme obavljanja
                                    redovnog zanimanja, na putu od kuće do posla i pri povratku kući, kao i u svakodnevnim
                                    aktivnostima.</p>

                                    <p><span> U zavisnosti od toga koji su rizici ugovoreni osiguranik može ostvariti pravo na: </span></p>

                                    <li>  - osiguranu sumu za slučaj prirodne smrti,</li>
                                    <li>   - osiguranu sumu za slučaj smrti uslijed nezgode,</li>
                                    <li>   - procenat od osigurane sume koji odgovara procentu invaliditeta, ako je uslijed nezgode kod
                                    osiguranika nastupio invaliditet i</li>
                                    <li>   - naknadu troškova lečenja koje osiguranik sam plati i koji ne padaju na teret zdravstvenog
                                    osiguranja, a nastali su kao posljedica nezgode.</li>
                                </div>


                            </Col>
                        </Row>
                    </Container>



                </div>

            </>

        )
    }
}

export default DefaultLayout(combinedInsurance)
