import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import Isvg from 'react-inlinesvg';

import AnimtedButton from '../components/animatedButton.js';

import news1 from '../images/news/news1.png';
import news2 from '../images/news/news2.png';
import news3 from '../images/news/news3.png';


import moment from 'moment';


import {
    Container, Col, Row, Button
} from 'reactstrap';

export class news extends Component {
    render() {

        const buttonText = "DETALJNIJE";
        return (
            <div className="news">
                {!this.props.newsDetail ?
                    <div className="wrapper">
                        <h6>NOVOSTI</h6>
                    </div>
                    : null}
                <Container>
                    {!this.props.newsDetail ?
                        <>
                            <h1>NOVOSTI I OBAVEŠTENJA</h1>
                            <p>Novosti i obavještenja o našim uslugama.</p>
                        </>
                        : null}
                    <Row>
                        {this.props.items.map((item, idx) => {
                            return (

                                <Col xl={this.props.newsDetail ? "12" : "4"} lg={this.props.newsDetail ? "6" : "4"} md={this.props.newsDetail ? "6" : "4"}  >
                                    <div className="newsBox">
                                        <div className="wrappImage">
                                        <Link to={`/news/${item.title}/${item._id}`}>    <img src={item.image} className="img-fluid" /></Link>
                                            <span> <p>{moment.unix(item.time).format('DD')} <br /> {moment.unix(item.time).format('MMM')} </p></span>


                                        </div>
                                        <h4>{item.title}</h4>
                                        <p>{item.shortDescription}</p>


                                        <Link to={`/news/${item.title}/${item._id}`}>
                                            <AnimtedButton buttonText={buttonText} />

                                        </Link>
                                    </div>

                                </Col>
                            )
                        })}

                    </Row>

                </Container>
            </div>
        )
    }
}

export default news
