import React from 'react';
import mapIcon from '../images/pin.png';

import {
    Container,
    Row,
    Col,
} from 'reactstrap';

export default class Map extends React.Component {
    constructor(props) {
        super(props);
        this.initMap = this.initMap.bind(this);
        this.state = {};
    }

    initMap() {
        this.setState({
            _mapInit: true
        });
        var latLng;

        if (this.props.coords && this.props.coords.indexOf(',') !== -1) {
            latLng = new window.google.maps.LatLng(this.props.coords.split(',')[0], this.props.coords.split(',')[1]);
        } else {
            latLng = new window.google.maps.LatLng(44.7623214, 19.20739);

        }

        var map = new window.google.maps.Map(this.GoogleMap, {
            zoom: 18,
            center: latLng,
            mapTypeId: window.google.maps.MapTypeId.ROADMAP,
            disableDefaultUI: true,
            gestureHandling: "gestureHandling",

        });


        /*var marker = new window.google.maps.Marker({
            position: latLng, 
            map: map,
            icon: mapIcon
        });*/

        for (let i = 0; i < this.props.offices.length; i++) {
            if (this.props.offices[i] && this.props.offices[i].location && this.props.offices[i].location.indexOf(',') !== -1)
                var marker = new window.google.maps.Marker({
                    position: new window.google.maps.LatLng(this.props.offices[i].location.split(',')[0], this.props.offices[i].location.split(',')[1]),
                    map: map,
                    icon: mapIcon
                });

        }


        this.setState({
            map: map
        })

    }

    componentDidMount() {
        if (this.props.mapinit && !this.state._mapInit && this.props.offices && this.props.offices.length > 0) {
            this.initMap();
        }


    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.mapinit && !this.state._mapInit && this.props.offices && this.props.offices.length > 0) {
            this.initMap();
        }

        if (prevProps.coords !== this.props.coords && this.state.map && this.props.coords) {
            this.state.map.setCenter(new window.google.maps.LatLng(this.props.coords.split(',')[0], this.props.coords.split(',')[1]))
        }
    }

    render() {
        return (
            <div>

                {
                    this.props.mapinit ?
                        <div className="map" ref={(input) => { this.GoogleMap = input; }}>

                        </div>
                        : null
                }

            </div>
        );
    }
}
